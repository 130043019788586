import moment, { duration } from 'moment';
import { Internationalization } from '@syncfusion/ej2-base';
import { calculateDates, setSecondsToZero } from 'utils';

export const getMondayZeroToSixSchedules = (schedules, startDate, endDate) => {
    let mondayZeroToSixSchedules = []
    let newSchedules = []
    let allDayEvent = []
    const instance = new Internationalization();
    // Function that checks times between 00:00:00 and 06:00:00
    const checkTime = (time) => {
        const hours = time.getHours();
        return hours >= 0 && hours < 6;
    };

    // Time deconstruction function of strings in the format of HH:MM:SS
    const deconstructTimeString = (time) => {
        return time.split(':');
    };

    const isZeroToSix = (schedule) => {
        const startDateValue = setSecondsToZero(
            calculateDates(
                schedule.dayOfWeek,
                1,
                schedule.startTime,
                schedule.endTime,
                { startDate, endDate })
            ['startTime']);
        const endDateValue = moment(
            calculateDates(
                schedule.dayOfWeek,
                1,
                schedule.startTime,
                schedule.endTime,
                { startDate, endDate })
            ['endTime']);
        const endDateTime = moment(schedule.endTime, "HH:mm:ss");
        const startDateTime = moment(startDateValue, "HH:mm:ss");

        const duration = moment.duration(endDateValue.diff(startDateTime)).asHours();
        const absDuration = Math.abs(duration);
        if (schedule.startTime === schedule.endTime || (moment(startDateValue).toDate().getHours() < 6 && endDateValue.toDate().getHours() > 6 || absDuration > 6)) {
            allDayEvent.push(schedule)
        }

        return moment(startDateValue).isBefore(endDateValue) && checkTime(startDateValue);
    }

    const adjustSchedules = (addToSundaySchedules, schedules) => {
        let sundayBroadcastSchedules = []; // Schedules to be added to Sunday
        let adjustedSchedules = []; // Adjusted schedules data for schedules.programmes

        const getBlockName = (day, date) => { // Mon00:00 - Removing week number
            const hour = date.toTimeString().split(':')[0];
            const min = date.toTimeString().split(':')[1];

            const time = `${hour}:${min}`;
            return `${day}${time}`;
        };

        if (addToSundaySchedules.length > 0) {
            // Moving Monday 00:00 - 06:00 schedules to Sunday
            sundayBroadcastSchedules = addToSundaySchedules.map((schedule) => {
                const startHour = deconstructTimeString(schedule.startTime)[0];
                const startMinute = deconstructTimeString(schedule.startTime)[1];
                const startSecond = deconstructTimeString(schedule.startTime)[2];
                const startDateTime = moment(startDate).hours(startHour).minutes(startMinute).seconds(startSecond);
                schedule.week = 2;
                schedule.blockName = getBlockName(instance.formatDate(new Date(startDate), { skeleton: 'E' }), startDateTime._d);
                return schedule;
            });

            // After setting the schedules to Sunday, adjust the schedules data
            adjustedSchedules = schedules.map((schedule) => {
                sundayBroadcastSchedules?.map((broadcastSchedule) => {
                    if (schedule.id === broadcastSchedule.id) {
                        schedule.week = broadcastSchedule.week;
                        schedule.blockName = broadcastSchedule.blockName
                    }
                })
                if (!schedule.week || schedule.week === 0)
                    schedule.week = 1;
                return schedule;
            });
        }
        if (allDayEvent[0]) {
            allDayEvent = {
                ...allDayEvent[0],
                week: 1,
            };
            adjustedSchedules.push(allDayEvent)
        }
        return adjustedSchedules;
    }

    if (schedules) {
        const removeSchedulesAfterSunday = schedules.filter((schedule) => {
            return schedule.week && schedule.week < 2 || schedule.week === 0;
        });
        // Filter out 00:00 - 06:00 schedules on Monday
        if (removeSchedulesAfterSunday.length > 0) {
            mondayZeroToSixSchedules = removeSchedulesAfterSunday.filter((schedule) => {
                if (schedule.dayOfWeek === 1) {
                    return isZeroToSix(schedule);
                }
            });
            newSchedules = adjustSchedules(mondayZeroToSixSchedules, removeSchedulesAfterSunday);
        } else {
            mondayZeroToSixSchedules = schedules.filter((schedule) => {
                if (schedule.dayOfWeek === 1) {
                    return isZeroToSix(schedule);
                }
            });
            newSchedules = adjustSchedules(mondayZeroToSixSchedules, schedules);
        }
    }

    return mondayZeroToSixSchedules.length > 0
        ? newSchedules
        : schedules;
};