import moment from "moment";

/**
 * Checks if a given event (data) overlaps with any events in the event list, excluding itself.
 * @param {Object} data - The event to check for overlaps, containing StartTime, EndTime, and Id.
 * @param {Array} eventList - Array of existing events, each with StartTime, EndTime, and Id.
 * @returns {boolean} - True if there is an overlap with any other event, false otherwise.
 */
export function isEventOverlapping(data, eventList) {
  const dataStart = moment(data.StartTime);
  const dataEnd = moment(data.EndTime);

  for (let event of eventList) {
    // Skip checking overlap with the same event (based on Id)
    if (data.Id === event.Id) continue;

    const eventStart = moment(event.StartTime);
    const eventEnd = moment(event.EndTime);

    // Check if data overlaps with the current event
    if (dataStart.isBefore(eventEnd) && dataEnd.isAfter(eventStart)) {
      return true; // Overlap found
    }
  }

  return false; // No overlap found
}