import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { AppBarComponent } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
  Browser,
  closest,
  extend,
  isNullOrUndefined,
  remove,
  removeClass,
  KeyboardEvents,
  Collection,
} from '@syncfusion/ej2-base';
import { Internationalization } from "@syncfusion/ej2-base";
import { DataManager, Query } from '@syncfusion/ej2-data';
import {
  Day,
  DragAndDrop,
  ExcelExport,
  ICalendarExport,
  ICalendarImport,
  Inject,
  Month,
  Print,
  Resize,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  Timezone,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
  Year,
} from '@syncfusion/ej2-react-schedule';
import moment, { tz } from 'moment-timezone';
import {
  Calendar,
  ContextMenu,
  DateHeaderTemplate,
  EventTemplate,
  InterstitialPanel,
  QuickInfoTemplate,
  Settings,
  SettingsPanel,
} from './';
import './OpScheduler.css';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import { setSecondsToZero } from 'utils';
import AddBlockIcon from 'assets/icons/Add-Block.svg';
import useStore from 'store/AccountStore';
import { getHourAndMinutes } from 'utils';
import { isEventOverlapping } from './utils';

export const OpScheduler = (props) => {
  const {
    actionButtonPanel = null,
    actionButtonHeaderPanel = null,
    customTooltipTemplate = null,
    dataSource = null,
    dateHeaderIcon = null,
    dateHeaderTemplate = null,
    disabled = false,
    editorTemplate = null,
    eventLookupList = '',
    eventTemplate = null,
    eventFields = null,
    eventRendered = null,
    headerComponent = null,
    heightBuffer = 0,
    onCellDoubleClick = null,
    onEventCreate = null, // new prop
    onEventDrag = null, // new prop
    onEventResize = null, // new prop
    onExport = null,
    onImport = null,
    onPaste = null,
    onPlaylistClick = null,
    onSlotCut = null,
    onSlotDeleted = null, // rename: onEventDelete
    onSlotDragStop = null, // subject for deletion
    onSlotDuplicated = null, // rename: onEventDelete
    onSlotModified = null,
    onSlotResizeStop = null, // subject for deletion
    onUndo = null, // subject for deletion
    readOnly = false,
    schedule = null,
    loading = false,
    settings = [
      'FIRST DAY OF WEEK',
      'SLOT DURATION',
      'SLOT INTERVAL',
      'TIME FORMAT',
      'CLOCKTYPES',
    ],
    showHeaderBar = true,
    suppressContextMenu = false,
    suppressExporting = false,
    supressImporting = false,
    suppressOverlappingSchedules = false,
    suppressTooltip = false,
    suppressQuickInfo = false,
    undoButton = true,
  } = props;
  const { user } = useStore((state) => state);

  const { Collections } = Calendar;
  const { Timezones, TimeFormats, BroadcastTimes } = Settings;

  let keyBoardAction = null;
  let selectedTarget;
  const currentView = 'Week';
  const isTimelineView = false;
  const contextMenuObj = useRef(null);
  const scheduleObj = useRef(null);
  const sidebarRef = useRef(null);
  const interstitialRef = useRef(null);

  const [eventTooltip, setEventTooltip] = useState(true);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(1);
  const [timeFormat, setTimeFormat] = useState('HH:mm');
  const [copiedEventData, setCopiedEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isResizing, setIsResizing] = useState(false);
  const [isClockTypeBroadcast, setClockTypeBroadcast] = useState(user?.clockType === 'Broadcast');
  const [eventTemplatesLoading, setEventTemplatesLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([]);

  const keyConfigs = {
    cut: 'ctrl+x',
    copy: 'ctrl+c',
    paste: 'ctrl+v',
  };

  const handleEventData = (events) => {
    if (events && events.length) {
      return events.map((event, index) => {
        return {
          Id: index + 1,
          CalendarId: 1,
          IsReadonly: false,
          State: { timeFormat },
          ...(eventFields && { ...eventFields(event) }),
        };
      });
    }
    return [];
  };

  const handleOnCellDoubleClick = React.useCallback((args) => {
    // disrupts default behavior of scheduler on double click
    args.cancel = true;
    
    if (isClockTypeBroadcast) {
      args = {
        ...args,
        endTime: moment(args.endTime).add(6, 'hours').toDate(),
        startTime: moment(args.startTime).add(6, 'hours').toDate(),
      };
    }

    if (onCellDoubleClick) {
      onCellDoubleClick({
        data: {
          ...args,
          State: { timeFormat },
        },
        schedulerActions: schedulerActions(scheduleObj),
      });
    }
  }, [isClockTypeBroadcast]);

  const handleOnSlotDragStop = (args) => {
    const prev = scheduleObj.current.eventsData.find(
      (o) => o.Id === args.data.Id
    );
    const prevMoment = moment(prev.StartTime);
    const newMoment = moment(args.data.StartTime);
    const currentData = scheduleObj.current.eventsData; //Array
    const newData = args.data;

    if (suppressOverlappingSchedules) {
      // Check if the new schedule overlaps with any existing schedules
      const hasOverlappingSchedules = currentData
        .map((schedule) => {
          //
          // Exclude the current schedule from the check
          if (!(schedule.Id === newData.Id)) {
            const isOverlapping =
              moment(newData.StartTime).isBetween(
                moment(schedule.StartTime),
                moment(schedule.EndTime)
              ) ||
              moment(newData.EndTime).isBetween(
                moment(schedule.StartTime),
                moment(schedule.EndTime)
              ) ||
              moment(newData.StartTime).isSame(moment(schedule.StartTime)) ||
              moment(newData.EndTime).isSame(moment(schedule.EndTime));
            return isOverlapping;
          }
          return false;
          // returns true if there is an overlap
        })
        .some((item) => item);

      if (!hasOverlappingSchedules) {
        if (!prevMoment.isSame(newMoment)) {
          onSlotDragStop({
            ...args,
            data: {
              ...args.data,
              State: {
                timeFormat,
              },
            },
            draggedData: {
              ...scheduleObj.current.eventsData.find(
                (o) => o.Id === args.data.Id
              ),
            },
          });
        }
      }
    } else {
      if (!prevMoment.isSame(newMoment)) {
        onSlotDragStop({
          ...args,
          data: {
            ...args.data,
            State: {
              timeFormat,
            },
          },
          draggedData: {
            ...scheduleObj.current.eventsData.find(
              (o) => o.Id === args.data.Id
            ),
          },
        });
      }
    }
  };

  const handleOnSlotResizeStart = (args) => {
    setIsResizing(true);
  };

  const handleOnSlotResizeStop = (args) => {
    const currentData = scheduleObj.current.eventsData;
    const newData = args.data;

    if (suppressOverlappingSchedules) {
      const hasOverlappingSchedules = currentData
        .map((schedule) => {
          if (!(schedule.Id === newData.Id)) {
            const isOverlapping =
              moment(newData.StartTime).isBetween(
                moment(schedule.StartTime),
                moment(schedule.EndTime)
              ) ||
              moment(newData.EndTime).isBetween(
                moment(schedule.StartTime),
                moment(schedule.EndTime)
              ) ||
              moment(newData.StartTime).isSame(moment(schedule.StartTime)) ||
              moment(newData.EndTime).isSame(moment(schedule.EndTime));
            return isOverlapping;
          }
          return false;
        })
        .some((item) => item);

      if (!hasOverlappingSchedules) {
        onSlotResizeStop({
          ...args,
          data: {
            ...args.data,
            State: {
              timeFormat,
            },
          },
          draggedData: {
            ...scheduleObj.current.eventsData.find(
              (o) => o.Id === args.data.Id
            ),
          },
        });
      }
    } else {
      onSlotResizeStop({
        ...args,
        data: {
          ...args.data,
          State: {
            timeFormat,
          },
        },
        draggedData: {
          ...scheduleObj.current.eventsData.find((o) => o.Id === args.data.Id),
        },
      });
    }

    // onSlotResizeStop({
    //   ...args,
    //   data: {
    //     ...args.data,
    //     State: { timeFormat, timezone },
    //   },
    //   draggedData: {
    //     ...scheduleObj.current.eventsData.find((o) => o.Id === args.data.Id),
    //   },
    // });
  };

  const handleSchedulerTimeFormat = (args) => {
    setTimeFormat(args.value);
  };

  const onChangeTimeFormat = () => {
    const timeFormatData = TimeFormats.map((value) => {
      return { Name: value.Name, value: value.Value };
    });

    if (timeFormat == timeFormatData[0].value) {
      handleSchedulerTimeFormat(timeFormatData[1]);
    } else {
      handleSchedulerTimeFormat(timeFormatData[0]);
    }
  };

  const generateEvents = useCallback(() => {
    let eventData = dataSource
      ? handleEventData(dataSource[eventLookupList])
      : [];

    for (let event of eventData) {
      event.StartTime = setSecondsToZero(event.StartTime);
      event.EndTime = setSecondsToZero(event.EndTime);
    }

    if (isClockTypeBroadcast) {
      // Reducing 6 hours to appear the blocks to the correct time slots on broadcast clock type
      eventData.map((event) => {
        event.StartTime = moment(event.StartTime).subtract(6, 'hour').toDate()
        event.EndTime = moment(event.EndTime).subtract(6, 'hour').toDate()
      })
    }
    return eventData;
  }, [dataSource]);

  const toggleSidebar = () => {
    sidebarRef.current.toggle();
    if (showBackdrop) {
      setShowBackdrop(true);
    }
  };

  const toggleInterstitialSidebar = () => {
    interstitialRef.current.toggle();
    if (showBackdrop) {
      setShowBackdrop(true);
    }
  };

  const getScheduleStartPeriod = React.useCallback(() => {
    if (schedule && schedule.start) {
      return new Date(schedule.start);
    }
    return new Date();
  }, [schedule.start]);

  const getScheduleEndPeriod = React.useCallback(() => {
    if (schedule && schedule.end) {
      return new Date(schedule.end);
    }
    return new Date();
  }, [schedule.end]);

  const getNumberOfWeeksFromPeriod = React.useCallback(() => {
    if (schedule) {
      // Start date
      const startDate = moment(getScheduleStartPeriod());
      // End date
      const endDate = moment(getScheduleEndPeriod());
      // Calculate the difference in days between the two dates
      const difference = endDate.diff(startDate, 'days', true);
      // Calculate the number of weeks
      return Math.ceil((difference) / 7);
    }
    return 1;
  }, [schedule, getScheduleStartPeriod, getScheduleEndPeriod]);

  const getNumberOfMonthsFromPeriod = React.useCallback(() => {
    if (schedule) {
      // Start date
      const startDate = getScheduleStartPeriod();
      const endDate = getScheduleEndPeriod();
  
      if (startDate && endDate) {
        // Calculate the difference in months between the two dates, accounting for years
        const yearDifference = endDate.getFullYear() - startDate.getFullYear();
        const monthDifference = endDate.getMonth() - startDate.getMonth();
  
        // Total months difference considering the year
        const totalMonths = yearDifference * 12 + monthDifference;
  
        // Add 1 if you want inclusive of both months
        return totalMonths + 1;
      }
    }
    return 1;
  }, [schedule, getScheduleStartPeriod, getScheduleEndPeriod]);

  const keyFunction = (e) => {
    switch (e.action) {
      case 'cut':
      case 'copy':
        var selectedEvent = document.querySelector('.e-appointment-border');
        const eventData = scheduleObj.current.getEventDetails(selectedEvent); //get selected event details
        setCopiedEventData({ ...eventData, isCut: e.action == 'cut' });
        break;
      case 'paste':
        var selectedCells = scheduleObj.current.getSelectedElements(); //to get the selected cells
        var cellDetail = scheduleObj.current.getCellDetails(selectedCells);
        const eventDetail = {
          ...copiedEventData,
          StartTime: cellDetail.startTime,
          EndTime: cellDetail.endTime,
        };
        onPaste && onPaste(eventDetail);
        setCopiedEventData({ ...copiedEventData, isCut: false });
        break;
    }
  };

  const _renderQuickInfoContent = (props) => {
    return (
      <div className="quick-info-content">
        {props.elementType === 'cell' ? null : (
          /** Quick Info Custom Template */
          <QuickInfoTemplate {...props} />
        )}
      </div>
    );
  };

  const _renderQuickInfoFooter = (props) => {
    return (
      <div className="quick-info-footer">
        {props.elementType === 'cell' ? null : (
          <div className="event-footer">
            <ButtonComponent
              id="more-details"
              cssClass="e-flat"
              content="Edit"
              isPrimary={true}
              {...(onSlotModified && {
                onClick: () =>
                  onSlotModified({
                    data: { ...props, State: { timeFormat }},
                    schedulerActions: schedulerActions(scheduleObj),
                  })
              })}
            />
            <ButtonComponent
              id="delete"
              cssClass="e-flat"
              content="Delete"
              {...(onSlotDeleted && {
                onClick: () => {
                  scheduleObj.current.deleteEvent(props);
                  onSlotDeleted({
                    data: { ...props, State: { timeFormat} },
                    schedulerActions: schedulerActions(scheduleObj),
                  });
                  scheduleObj.current.closeQuickInfoPopup();
                }
              })}
            />
          </div>
        )}
      </div>
    );
  };

  const _renderHeaderComponent = useCallback(() => {
    return (
      <div className="e-appbar-spacer">
        {/* Tabs between Programmes and Grading goes here */}
        {/* {headerComponent ? headerComponent.renderTabs(activatedTab, setActivatedTab) : null} */}
        {headerComponent}
      </div>
    );
  }, [headerComponent]);

  const _renderDateHeader = useCallback(
    (args) => {
      if (dateHeaderTemplate) {
        return dateHeaderTemplate(args);
      } else {
        return (
          <DateHeaderTemplate
            args={args}
            toggleInterstitialSidebar={toggleInterstitialSidebar}
            onPlaylistClick={onPlaylistClick}
            icon={dateHeaderIcon}
          />
        );
      }
    },
    [dateHeaderTemplate]
  );

  const EventTemplateMemo = React.memo((args) => {
    // Your custom event template logic
    return eventTemplate(args);
  });

  const _renderEvent = useCallback(
    (args) => {
      if (eventTemplate) {
        return <EventTemplateMemo {...{...args}} />;
      } else {
        return <EventTemplate args={args} />;
      }
    },
    [EventTemplateMemo, eventTemplate]
  );

  const serviceManager = useMemo(() => {
    const baseServices = [
      Day,
      Week,
      WorkWeek,
      Month,
      Year,
      Print,
      ExcelExport,
      ICalendarImport,
      ICalendarExport,
    ];
    const additionalServices = [];

    if (!readOnly) {
      additionalServices.push(DragAndDrop);
      additionalServices.push(Resize);
    }

    return [...baseServices, ...additionalServices];
  }, [readOnly]);

  const schedulerSettingsProps = {
    eventTooltip,
    scheduleObj,
    sidebarRef,
    showBackdrop,
    suppressTooltip,
    timeFormat,
    handleSchedulerTimeFormat,
    setEventTooltip,
    toggleSidebar,
    settings,
  };

  const interstitialProps = {
    content: () => <div>Content here</div>,
    interstitialRef,
    toggleInterstitialSidebar,
  };

  const toolTipForBroadcastTemplate = (args) => {
    //Get 'Oct 5' date string format
    const getDateString = (date) => {
      return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
      }).format(new Date(date));
    };

    //Get 'Oct 5, 2021' date string format
    const getDateStringWithYear = (date) => {
      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }).format(new Date(date));
    };

    const isSameDay = (start, end) => {
      return moment(start).isSame(end, 'day');
    }

    return (
      <div>
        <div className="e-subject">
          {args.Subject?.length > 0 ? args.Subject : args.EventName}
        </div>
        <div className="e-details">
          {isSameDay(args.StartTime, args.EndTime) ? `${getDateStringWithYear(args.StartTime)}`
            : `${getDateString(args.StartTime)} - ${getDateStringWithYear(args.EndTime)}`}
        </div>
        <div className="e-all-day">{`${getHourAndMinutes(args.StartTime, args.State.timeFormat)} - ${getHourAndMinutes(args.EndTime, args.State.timeFormat)}`}</div>
      </div>
    )
  };

  const majorSlotTemplate = useCallback((args) => {
    const instance = new Internationalization();
    const date = isClockTypeBroadcast
      ? moment(args.date).add(6, 'hours')._d
      : args.date;
    return (<div>{
      instance.formatDate(date, {
        skeleton:
          timeFormat === 'HH:mm' ? 'Hm' : 'hm'
      })}</div>);
  }, [isClockTypeBroadcast, timeFormat]);

  const timeScale = useMemo(() => {
    return {
      enable: true,
      interval: 60,
      slotCount: 2,
      majorSlotTemplate: majorSlotTemplate,
      minorSlotTemplate: null
    };
  }, [isClockTypeBroadcast, majorSlotTemplate]);

  const handleEventTemplateOnBroadcast = useCallback((args) => {
    let data = { ...args };
    if (isClockTypeBroadcast) {
      data.StartTime = moment(args.StartTime).add(6, 'hour').toDate()
      data.EndTime = moment(args.EndTime).add(6, 'hour').toDate()
    }
    return _renderEvent(data)
  }, [_renderEvent, isClockTypeBroadcast]);

  useEffect(() => {
    // Change between Standard and Broadcast clocktype
    setClockTypeBroadcast(user?.clockType === 'Broadcast');
  }, [user]);

  useEffect(() => {
    setEventTooltip(!suppressTooltip);
  }, [suppressTooltip]);

  useEffect(() => {
    let updatedView = currentView;
    switch (currentView) {
      case 'Day':
      case 'TimelineDay':
        updatedView = isTimelineView ? 'TimelineDay' : 'Day';
        break;
      case 'Week':
      case 'TimelineWeek':
        updatedView = isTimelineView ? 'TimelineWeek' : 'Week';
        break;
      case 'WorkWeek':
      case 'TimelineWorkWeek':
        updatedView = isTimelineView ? 'TimelineWorkWeek' : 'WorkWeek';
        break;
      case 'Month':
      case 'TimelineMonth':
        updatedView = isTimelineView ? 'TimelineMonth' : 'Month';
        break;
      case 'Year':
      case 'TimelineYear':
        updatedView = isTimelineView ? 'TimelineYear' : 'Year';
        break;
      case 'Agenda':
        updatedView = 'Agenda';
        break;
      default:
        updatedView = isTimelineView ? 'TimelineWeek' : 'Week';
        break;
    }
    scheduleObj.current.currentView = updatedView;
    let defaultFirsDayOfWeek = 1;
    if (schedule) {
      setFirstDayOfWeek(getScheduleStartPeriod().getDay());
    } else {
      setFirstDayOfWeek(defaultFirsDayOfWeek)
    }
  }, [isTimelineView, currentView, schedule]);

  useEffect(() => {
    keyBoardAction = new KeyboardEvents(scheduleObj.current.element, {
      keyAction: keyFunction,
      keyConfigs: keyConfigs,
    });
    return () => {
      if (keyBoardAction !== null) keyBoardAction.destroy();
    };
  }, [dataSource, copiedEventData]);

  // useEffect(() => {
  //   if (
  //     !dataSource[eventLookupList] ||
  //     dataSource[eventLookupList].length === 0
  //   )
  //     // when null, eventLookupList is not found
  //     setTimeout(() => setIsLoading(false), 200);
  //   else {
  //     if (dataSource[eventLookupList].length > 0)
  //       // when not null
  //       setTimeout(() => setIsLoading(false), timoutDelay);
  //   }

  //   if (!loading) setIsLoading(false);
  // }, [dataSource, loading]);











  // CLEAN CODE MUST START HERE
  let eventBlockRendered = 0;
  const eventsLength = generateEvents().length;

  const [actionStack, setActionStack] = useState([]);
  const [eventHistory, setEventHistory] = useState([]);

  const handleEventRendered = React.useCallback((args) => {
    // Event Block counter. Keeps loading until the number of schedules matches the
    // overall datasource event count.
    eventBlockRendered += 1;
  
    // Get the event element
    const eventElement = args.element;
  
    // Find the .e-subject element within the event element
    const subjectElement = eventElement.querySelector('.e-subject');
    // Targeting .e-time
    const contentElement = eventElement.querySelector('.e-time');
    
    // Extract the EventTemplate's returned JSX element
    const templatedEvent = eventTemplate(args.data);
    const jsxContent = templatedEvent.props.children;
  
    if (contentElement) {
      // Removes the DEFAULT Time Slot Label (00:00 - 03:00)
      contentElement.innerHTML = null;
    }

    let subjectDom;
    let contentDom = '';
    jsxContent.forEach((child) => {
      // Manipulate Subject DOM's Inner Child
      if (subjectElement) {
        const originalStyles = child.props.style || {};
        const originalClassName = child.props.className || ''
        if (originalClassName.includes('op-event-subject'))  {
          subjectDom = ReactDOMServer.renderToStaticMarkup(
            <div
              className={`${originalClassName} op-event-subject`}
              style={{
                fontSize: '14px',
                fontWeight: 600,
                marginBottom: '4px',
                overflow: 'hidden',
                padding: '4px 2px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                display: 'block',
                ...originalStyles,
              }}
            >
              {child.props.children || 'Title Not Found'}
            </div>
          );
        }
      }

      if (contentElement) {
        if (child.props && child.props.className === 'op-event-content') {
          const validChildren = React.Children.toArray(child.props.children);
          validChildren.forEach((validChild) => {
            if (isClockTypeBroadcast) {
              if (
                validChild.props.className &&
                (validChild.props.className.includes('op-event-schedule') || 
                 validChild.props.className === 'op-event-schedule')
              ) {
                const timeData = validChild.props.children.trim();

                // Check if the time data contains both time range and duration
                const hasDuration = timeData.includes(',');

                let timeRange, originalDuration;
                if (hasDuration) {
                  [timeRange, originalDuration] = timeData.split(',');
                  originalDuration = originalDuration.trim();
                } else {
                  timeRange = timeData;
                  // No duration present
                  originalDuration = '';
                }

                const [startTime, endTime] = timeRange.split(' - ');

                const newStartTime = moment(startTime, timeFormat).add(6, 'hours').format(timeFormat);
                const newEndTime = moment(endTime, timeFormat).add(6, 'hours').format(timeFormat);

                const newTimeRange = originalDuration
                ? `${newStartTime} - ${newEndTime}, ${originalDuration}`
                : `${newStartTime} - ${newEndTime}`;

                validChild = React.cloneElement(validChild, {
                  children: newTimeRange,
                });
              }
            }
            contentDom += ReactDOMServer.renderToStaticMarkup(
              <div
                style={{
                  padding: '0px 2px',
                  width: '100%',
                  display: 'block',
                  fontSize: '12px',
                }}
              >
                {validChild}
              </div>
            );
          });
        }
      }
    });

    // Set the subject content
    if (subjectElement && subjectDom) subjectElement.innerHTML = subjectDom;
    // Set the content content
    if (contentElement && contentDom) contentElement.innerHTML = contentDom;
  
    if (eventBlockRendered === eventsLength) setEventTemplatesLoading(false);
  }, [timeFormat]);

  const schedulerActions = React.useCallback((scheduleRef) => {
    const scheduler = scheduleRef.current;
    
    // const normalization = (data) => Array.isArray(data) ? data.map(d => eventFields(d)) : [eventFields(data)];

    // Function to validate if data matches the structure of the first event in the Scheduler
    const isEventFieldFormat = (data, referenceEvent) => {
      if (!referenceEvent || !data) return false;

      return Object.keys(referenceEvent).every((key) => {
        if (!(key in data)) return false; // Key doesn't exist in incoming data
        const expectedType = typeof referenceEvent[key];
        const actualType = typeof data[key];

        // Special case for Date objects
        if (referenceEvent[key] instanceof Date) {
          return data[key] instanceof Date;
        }

        // Allow undefined fields for optional properties
        return actualType === expectedType || data[key] === undefined;
      });
    };

    // Normalization function
    const normalization = (data) => {
      const referenceEvent = allEvents[0]; // Get the first event as a reference

      return Array.isArray(data)
        ? data.map((d) => (isEventFieldFormat(d, referenceEvent) ? d : eventFields(d)))
        : isEventFieldFormat(data, referenceEvent)
          ? [data]
          : [eventFields(data)];
    };

    // Validator for possible duplicate/overlapping events
    const EventConflictValidator = (data) => {
      const normalizedData = normalization(data);
      return normalizedData.some(newEvent => {
        const isAvailable = scheduler.isSlotAvailable(newEvent.StartTime, newEvent.EndTime);
        // If slot is not available, return true (overlap exists)
        return !isAvailable;
      });
    };

    // Modify event action
    const EventChange = (data) => {
      const normalizedData = normalization(data);
      normalizedData.forEach(event => {
        if (isClockTypeBroadcast) {
          event = {
            ...event,
            EndTime: moment(event.EndTime).subtract(6, 'hours').toDate(),
            StartTime: moment(event.StartTime).subtract(6, 'hours').toDate(),
          }
        }
        scheduler.saveEvent(event)
      });
      scheduler.refreshEvents();
    };

    // Create event action
    const EventCreate = (data) => {
      const normalizedData = normalization(data);
      // scheduler.addEvent(normalizedData);
      normalizedData.forEach(event => {
        if (isClockTypeBroadcast) {
          event = {
            ...event,
            EndTime: moment(event.EndTime).subtract(6, 'hours').toDate(),
            StartTime: moment(event.StartTime).subtract(6, 'hours').toDate(),
          }
        }
        scheduler.addEvent(event);
      });
      trackAction("create", normalizedData);
      scheduler.refreshEvents();
    };

    // Delete event action
    const EventDelete = (data) => {
      const normalizedData = normalization(data);
      normalizedData.map(d => scheduler.deleteEvent(d));
    };

    const EventGetAll = () => allEvents;

    return {
      EventConflictValidator,
      EventChange,
      EventCreate,
      EventDelete,
      EventGetAll,
    }
  }, [scheduleObj, allEvents, eventFields]);

  const handleContextMenuOpen = (args) => {
    if (suppressContextMenu) {
      args.cancel = true;
      return;
    }

    let targetElement = args.event.target;
    selectedTarget = closest(targetElement, '.e-appointment');

    // Closes the QuickInfo Modal (if opened)
    scheduleObj.current.closeQuickInfoPopup(); 

    if (isNullOrUndefined(selectedTarget) || closest(targetElement, '.e-contextmenu')) {
      args.cancel = true;
      return;
    }
    
    if (selectedTarget.classList.contains('e-appointment')) {
      const actionItems = [
        { action: 'Save', handler: onSlotModified },
        { action: 'Duplicate', handler: onSlotDuplicated },
        { action: 'Cut', handler: onSlotCut },
        { action: 'Delete', handler: onSlotDeleted }
      ];
    
      // Separate contextItems (to show) and hideItems (to hide)
      const contextItems = actionItems
        .filter(item => item.handler)
        .map(item => item.action);
    
      const hideItems = actionItems
        .filter(item => !item.handler)
        .map(item => item.action);
    
      // Show and hide context menu items
      contextMenuObj.current.showItems(contextItems, true);
      contextMenuObj.current.hideItems(
        [
          'Add',
          'AddRecurrence',
          'Today',
          'EditRecurrenceSlot',
          'DeleteRecurrenceSlot',
          ...hideItems,
        ],
        true
      );
    };
  };

  const handleContextMenuSelect = (args) => {
    let selectedMenuItem = args.item.id;
    let eventObj = {};

    if (selectedTarget && selectedTarget.classList.contains('e-appointment')) {
      eventObj = scheduleObj.current.getEventDetails(selectedTarget);
    }

    switch (selectedMenuItem) {
      // Modify Context Menu
      case 'Save':
        if (scheduleObj.current && onSlotModified) {
          onSlotModified({
            data: { ...eventObj, State: { timeFormat }},
            schedulerActions: schedulerActions(scheduleObj),
          });
        }
        break;
      // Duplicate Context Menu
      case 'Duplicate':
        if (scheduleObj.current) {
          onSlotDuplicated({
            data: { ...eventObj, State: { timeFormat } },
            schedulerActions: schedulerActions(scheduleObj),
          });
        }
        break;
      // Cut Context Menu
      case 'Cut':
        onSlotCut && onSlotCut({ 
          data: { ...eventObj, State: { timeFormat } },
          schedulerActions: schedulerActions(scheduleObj),
         });
        break;
      // Delete Context Menu
      case 'Delete':
        if (scheduleObj.current) {
          // Make sure eventObj exists and is valid before trying to delete
          if (eventObj && Object.keys(eventObj).length) {
            // Delete the event using the object
            scheduleObj.current.deleteEvent(eventObj);
            onSlotDeleted({
              data: { ...eventObj, State: { timeFormat} },
              schedulerActions: schedulerActions(scheduleObj),
            });
          } else {
            console.error('Invalid event object:', eventObj);
          }
        } else {
          console.error('Scheduler is not initialized yet.');
        }
        break;
      default:
        scheduleObj.current.selectedDate = new Date();
        break;
    }
  };

  const contextMenuProps = {
    contextMenuObj,
    handleContextMenuOpen,
    handleContextMenuSelect,
  };

  const handleTooltip = React.useCallback((args) => {
    const data = isClockTypeBroadcast
      ? {
          ...args,
          Subject: args.Subject ?? 'No title found',
          StartTime: moment(args.StartTime).add(6, 'hours').toDate(),
          EndTime: moment(args.EndTime).add(6, 'hours').toDate(),
        }
      : args;

    return customTooltipTemplate ? customTooltipTemplate(data) : toolTipForBroadcastTemplate(data);
  }, [isClockTypeBroadcast, customTooltipTemplate]);

  const onActionBegin = React.useCallback((args) => {
    if (
      args.requestType === 'eventCreate'
    ) {
      args.data[0] = {
        ...args.data[0],
        CalendarId: 1,
        IsReadonly: false,
        State: {
          timeFormat,
        },
      };

      let data = args.data instanceof Array ? args.data[0] : args.data;

      // Prevent Schedule Overlapping when `suppressOverlappingSchedules` is true
      if (suppressOverlappingSchedules) {
        args.cancel = !scheduleObj.current.isSlotAvailable(
          data.StartTime,
          data.EndTime
        );
      }
    }
  }, [suppressOverlappingSchedules]);

  const trackAction = (actionType, eventData, originalData = null) => {
    // Track actions for undo
    const newAction = { type: actionType, event: eventData, original: originalData };
    setActionStack((prevStack) => [...prevStack, newAction]);
  };

  const recordEventHistory = React.useCallback(() => {
    const scheduleRef = scheduleObj.current;
    if (!scheduleRef) return;
  
    const currentEvents = scheduleRef.eventSettings.dataSource;
    // Stack the current state of the data source
    setEventHistory((prev) => [...prev, JSON.parse(JSON.stringify(currentEvents))]); // Deep copy
  }, [eventHistory, scheduleObj]);

  const onActionComplete = (args) => {
    const eventData = args.data;
    setAllEvents(scheduleObj.current.eventSettings.dataSource);
    recordEventHistory();
    switch (args.requestType) {
      case "eventCreated":
        break;
      case "eventChanged":
        trackAction("update", eventData, args.changedRecords[0]);
        break;
      case "eventRemoved":
        trackAction("delete", eventData);
        break;
      default:
        break;
    }
  };

  const handleDragStop = React.useCallback((args) => {
    
    if (suppressOverlappingSchedules) {
      // Cross reference the data to ALL the events to check for possible overlapping schedules
      const isOverlap = isEventOverlapping(args.data, scheduleObj.current.getEvents());
      // Disrupt the drag action when schedule overlaps another AND `suppressOverlappingSchedules` is set to `true`
      args.cancel = isOverlap;
      return;
    };

    // Prepare to set the data to provide the correct start/end times by adding 6 hours to the dragged time slot
    if (isClockTypeBroadcast) {
      args = {
        ...args,
        data: {
          ...args.data,
          EndTime: moment(args.data.EndTime).add(6, 'hours').toDate(),
          end: moment(args.data.end).add(6, 'hours').toDate(),
          endDate: moment(args.data.endDate).add(6, 'hours').toDate(),
          StartTime: moment(args.data.StartTime).add(6, 'hours').toDate(),
          start: moment(args.data.start).add(6, 'hours').toDate(),
          startDate: moment(args.data.startDate).add(6, 'hours').toDate(),
        }
      };
    };

    if (onEventDrag) {
      // Invoke `onEventDrag` callback if provided
      onEventDrag({ 
        data: { ...args.data, State: { timeFormat } }, 
        schedulerActions: schedulerActions(scheduleObj)
      });
    };
  }, [isClockTypeBroadcast, suppressOverlappingSchedules, onEventDrag]);

  const handleResizeStop = React.useCallback((args) => {
    
    if (suppressOverlappingSchedules) {
      // Cross reference the data to ALL the events to check for possible overlapping schedules
      const isOverlap = isEventOverlapping(args.data, scheduleObj.current.getEvents());
      // Disrupt the drag action when schedule overlaps another AND `suppressOverlappingSchedules` is set to `true`
      args.cancel = isOverlap;
      return;
    };

    // Prepare to set the data to provide the correct start/end times by adding 6 hours to the dragged time slot
    if (isClockTypeBroadcast) {
      args = {
        ...args,
        data: {
          ...args.data,
          EndTime: moment(args.data.EndTime).add(6, 'hours').toDate(),
          end: moment(args.data.end).add(6, 'hours').toDate(),
          endDate: moment(args.data.endDate).add(6, 'hours').toDate(),
          StartTime: moment(args.data.StartTime).add(6, 'hours').toDate(),
          start: moment(args.data.start).add(6, 'hours').toDate(),
          startDate: moment(args.data.startDate).add(6, 'hours').toDate(),
        }
      };
    };

    if (onEventResize) {
      // Invoke `onEventResize` callback if provided
      onEventResize({ 
        data: { ...args.data, State: { timeFormat } }, 
        schedulerActions: schedulerActions(scheduleObj)
      });
    };
  }, [isClockTypeBroadcast, suppressOverlappingSchedules, onEventResize]);

  const handleUndoClick = React.useCallback(() => {
    
    if (!actionStack.length) return;

    const lastAction = actionStack[actionStack.length - 1];
    const schedulerObj = scheduleObj.current;
    
    // Clone the current events
    let updatedEvents = [...schedulerObj.eventSettings.dataSource];

    switch (lastAction.type) {
      case "create":
        // Undo create: Remove the created event(s)
        updatedEvents = updatedEvents.filter((event) => {
          return Array.isArray(lastAction.event)
            ? !lastAction.event.some((createdEvent) => createdEvent.Id === event.Id)
            : event.Id !== lastAction.event.Id;
        });
        break;

      case "update":
        // Undo update: Revert to the original event(s)
        const modifiedData = lastAction.event[0];
        updatedEvents = updatedEvents.map((event) => {
          if (Array.isArray(lastAction.event)) {
            if (event.Id === modifiedData.Id) {
              if (eventHistory && eventHistory.length > 1) {
                const revertedData = eventHistory[eventHistory.length - 2].find(eventHistory => eventHistory.Id === event.Id);
                return revertedData;
              } else {
                const revertedData = eventHistory[0].find(eventHistory => eventHistory.Id === event.Id);
                return revertedData;
              }
            }
            
            return event;
          } else {
            return event.Id === lastAction.original.Id ? lastAction.original : event;
          }
        });
        break;

      case "delete":
        // Undo delete: Add the deleted event(s) back
        updatedEvents = [
          ...updatedEvents,
          ...(Array.isArray(lastAction.event) ? lastAction.event : [lastAction.event]),
        ];
        break;

      default:
        break;
    }

    // Update the data source and refresh the scheduler
    schedulerObj.eventSettings.dataSource = updatedEvents;

    // Remove the last action from the stack
    setActionStack((prevStack) => prevStack.slice(0, -1));
    setEventHistory((prevHistory) => prevHistory.slice(0, -1));
  }, [actionStack, eventHistory]);

  React.useEffect(() => {
    scheduleObj.current.eventSettings.dataSource = [];
    let events = generateEvents();
    if (!events.length) setEventTemplatesLoading(false);
    scheduleObj.current.eventSettings.dataSource = events;
  }, [dataSource, generateEvents]);

  React.useEffect(() => {
    setIsLoading(loading);
    scheduleObj.current.refresh();
  }, [loading]);

  return (
    <>
      <div className="schedule-control-section">
        <div className="content-wrapper">
          {(isLoading || eventTemplatesLoading) && (
            <div
              style={{
                width: '100%',
                height: 'calc(100% - 50px)',
                zIndex: 100,
                background: 'var(--op2mise-color-white)',
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
            >
              <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                <BootstrapSpinner />
              </div>
            </div>
          )}
          <div className="schedule-overview">
            <AppBarComponent colorMode="Primary">
              {_renderHeaderComponent()}
              {!readOnly && (
                <div className="control-panel calendar-settings">
                  <ButtonComponent
                    id="addBtn"
                    cssClass="overview-toolbar-settings e-inherit"
                    // iconCss="e-icons e-plus"
                    iconPosition=" Top"
                    content=""
                    disabled={disabled}
                    title="Add"
                    style={{ padding: '8px 10px' }}
                    onClick={() => {
                      if (scheduleObj.current && onEventCreate) {
                        onEventCreate({
                          data: {
                            endTime: null,
                            startTime: null,
                            State: {
                              timeFormat,
                            },
                          },
                          schedulerActions: schedulerActions(scheduleObj),
                        });
                      }
                    }}
                  >
                    <img src={AddBlockIcon} alt="Add Block" title="Add" />
                  </ButtonComponent>
                </div>
              )}
              {(!readOnly && undoButton) && (
                <div className="control-panel calendar-settings">
                  <ButtonComponent
                    id="undoBtn"
                    cssClass="overview-toolbar-settings e-inherit"
                    iconCss="e-icons e-undo"
                    iconPosition="Top"
                    disabled={actionStack.length === 0}
                    content=""
                    onClick={handleUndoClick}
                    title="Undo"
                  />
                </div>
              )}
              {!supressImporting && (
                <div className="control-panel calendar-settings">
                  <ButtonComponent
                    id="uploadBtn"
                    cssClass="e-inherit"
                    iconCss="e-icons e-upload-1"
                    iconPosition="Top"
                    content=""
                    onClick={onImport}
                    disabled={disabled}
                    title="Import"
                  />
                </div>
              )}
              {!suppressExporting && (
                <div className="control-panel calendar-settings">
                  <ButtonComponent
                    id="exportBtn"
                    cssClass="e-inherit"
                    iconCss="e-icons e-download"
                    iconPosition="Top"
                    content=""
                    onClick={onExport}
                    disabled={disabled}
                    title="Export"
                  />
                </div>
              )}
              <div className="control-panel calendar-settings">
                <ButtonComponent
                  id="changeTimeFormatBtn"
                  cssClass="e-inherit"
                  iconCss="e-icons e-clock"
                  iconPosition="Top"
                  content=""
                  title="Change time format"
                  onClick={onChangeTimeFormat}
                  disabled={disabled}
                />
              </div>
              {actionButtonHeaderPanel && actionButtonHeaderPanel()}
              <div className="control-panel calendar-settings">
                <ButtonComponent
                  id="settingsBtn"
                  cssClass="overview-toolbar-settings e-inherit"
                  iconCss="e-icons e-settings"
                  iconPosition="Top"
                  content=""
                  onClick={toggleSidebar}
                  title="Settings"
                />
              </div>
            </AppBarComponent>
            <div className="overview-content">
              <div className="left-panel">
                <div className="overview-scheduler">
                  <ScheduleComponent
                    id="scheduler"
                    delayUpdate={true}
                    eventRendered={handleEventRendered}
                    toolbarItems={[
                      { name: 'Views', align: 'Right', visible: true },
                    ]}
                    actionBegin={onActionBegin}
                    actionComplete={onActionComplete}
                    allowDragAndDrop={true}
                    dragStop={handleDragStop}
                    resizeStop={handleResizeStop}

                    cellClick={(args) => (args.cancel = true)} // Disable creating new event on cell (#Remove when needed)
                    cellDoubleClick={handleOnCellDoubleClick}
                    cssClass={
                      getNumberOfWeeksFromPeriod() > 1
                        ? 'sf-theme-op2mise'
                        : 'sf-theme-op2mise-2'
                    }
                    currentView={currentView}
                    eventClick={(args) =>
                      suppressQuickInfo && (args.cancel = true)
                    }
                    eventDoubleClick={(args) => (args.cancel = true)} // disrupts default behavior of scheduler on double click
                    eventSettings={{
                      // dataSource: generateEvents(),
                      enableTooltip: eventTooltip,
                      spannedEventPlacement: 'TimeSlot',
                      // template: handleEventTemplateOnBroadcast,
                      ...(customTooltipTemplate || isClockTypeBroadcast ? { tooltipTemplate: handleTooltip } : {}),
                    }}
                    dateHeaderTemplate={_renderDateHeader}
                    firstDayOfWeek={firstDayOfWeek}
                    group={{ resources: ['Calendars'] }}
                    height={`calc(100vh - ${heightBuffer}px)`}
                    quickInfoTemplates={{
                      content: _renderQuickInfoContent,
                      footer: _renderQuickInfoFooter,
                    }}
                    ref={scheduleObj}
                    selectedDate={getScheduleStartPeriod()}
                    showHeaderBar={showHeaderBar}
                    showTimeIndicator={false}
                    timeFormat={timeFormat}
                    timeScale={timeScale}
                    width="100%"
                    workHours={{
                      highlight: true,
                      start: '00:00',
                      end: '23:59',
                    }}
                    // {...(onSlotDragStop && {
                    //   dragStop: handleOnSlotDragStop,
                    //   dragStart: handleOnSlotResizeStart,
                    // })}
                    // {...(onSlotResizeStop && {
                    //   resizeStop: handleOnSlotResizeStop,
                    //   resizeStart: handleOnSlotResizeStart,
                    // })}
                    // {...(editorTemplate && { editorTemplate })}
                  >
                    <ResourcesDirective>
                      <ResourceDirective
                        field="CalendarId"
                        title="Calendars"
                        name="Calendars"
                        dataSource={Collections}
                        query={new Query().where('CalendarId', 'equal', 1)}
                        textField="CalendarText"
                        idField="CalendarId"
                        colorField="CalendarColor"
                      />
                    </ResourcesDirective>
                    <ViewsDirective>
                      <ViewDirective
                        option="Week"
                        interval={getNumberOfWeeksFromPeriod()}
                        displayName="Week"
                      />
                      <ViewDirective
                        option="Month"
                        interval={getNumberOfMonthsFromPeriod()}
                      />
                      <ViewDirective option="Year" />
                    </ViewsDirective>
                    <Inject services={serviceManager} />
                  </ScheduleComponent>
                  <ContextMenu {...contextMenuProps} />
                </div>
              </div>
              <SettingsPanel {...schedulerSettingsProps} />
              <InterstitialPanel {...interstitialProps} />
            </div>
          </div>
        </div>
      </div >
      {actionButtonPanel && actionButtonPanel({ events: allEvents })
      }
    </>
  );
};
