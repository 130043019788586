import React, { useState, useRef, useEffect } from 'react';
import ScheduleService from 'components/schedule/ScheduleService';
import DateService from 'core/date.service';
import AuthService from 'core/auth.service';
import ImportFileModalComponent from 'components/schedule/react-scheduler/forms/import/ImportFileModalComponent';
import ImportLoadingModalComponent from 'components/schedule/react-scheduler/forms/import/ImportLoadingModalComponent';
import ImportConfirmModalComponent from 'components/schedule/react-scheduler/forms/import/ImportConfirmModalComponent';
import { queryParams } from '@syncfusion/ej2-base';
import ImportConfirmationModalComponent from 'components/channel-profiles/business-rules/modals/ImportConfirmationModalComponent';
import ImportResultModalComponent from './ImportResultModalComponent';
import styles from '../../schedule/react-scheduler/forms/import/ImportSlots.module.css';
import moment from 'moment';
import XLSX from 'sheetjs-style';
import { guid } from '@progress/kendo-react-common';
import { Constants } from './../helper/constants';
import { calculateDates, getDayOfWeek } from 'utils';
import { getMondayOfTheWeek } from 'utils';
import { addDaysToDate } from 'utils';

export function ImportComponent({
  closeModal,
  setScheduleData,
  scheduledata = [],
  clockStart = 0,
  scheduleInfo,
  schedulerData,
  setIsDirty,
  sampleTemplate,
  handleOnImportApi,
  importResultColumns,
  importResultFileTitle,
  getUpdatedList,
  setSchedules,
  titleGroupList,
  seriesList,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [headerData, setHeaderData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedList, setUploadedList] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [uploadedSlots, setUploadedSlots] = useState([]);
  const { channelId } = { ...scheduleInfo };
  const { schedules, period } = { ...schedulerData };
  const {
    programFields,
    gradingFields,
    minutageFields,
    fieldConstraints
  } = Constants;

  const loadingHeaderComponent = (
    <div className={styles.divHeader}>
      Importing and validating slots from{' '}
      <b>{selectedFile && selectedFile.name}</b>
    </div>
  );

  const isInvalid = (value) => {
    return (
      value === null || value === undefined || value === 0 || value.length === 0
    );
  };

  // Only for Grading and Minutage
  const cleanOutInvalidSlots = (data) => {
    data.map((schedule) => {
      if (sampleTemplate.includes('grade')) {
        if (isInvalid(schedule.grade)) {
          schedule.imported = false;
          schedule.result = 'Invalid grade input. Must be between 1 and 10';
        }
      } else if (sampleTemplate.includes('minutage')) {
        if (isInvalid(schedule.minutage)) {
          schedule.imported = false;
          schedule.result = 'Invalid minutage input.';
        }
      }
    });
  };

  const validateImportedFile = (data, importResultColumns) => {
    const columnHeaders = architectureTab === 'Program' ? programFields
      : architectureTab === 'Grading' ? gradingFields : minutageFields;
    let lowerCaseHeaders = columnHeaders.map((header) => header.toLowerCase());
    let validColumns = [];
    let importedColumnFields = data.length > 0 ? Object.keys(data[0]).map((header) => header.toLocaleLowerCase()) : [];
    if (importedColumnFields.length > 0) {
      validColumns = importedColumnFields.map((column) => {
        return lowerCaseHeaders.includes(column)
      }).some((column) => column === false);
    }
    return !validColumns;
  }

  const retrieveTitleGroups = (schedule) => {
    let found = [];
    if (schedule.titleGroupName) {
      found = titleGroupList.find((group) => group.name === schedule.titleGroupName)
    }
    return found;
  }

  const retrieveSeries = (schedule) => {
    let found = [];
    if (schedule.seriesName) {
      found = seriesList.find((item) => item.name === schedule.seriesName)
    }
    return found;
  }

  const validateRows = (data) => {
    let isOverlap = false;
    // Validate each field value
    data.map((schedule) => {
      importResultColumns.map((column) => {
        const value = schedule[column.field];
        const constraints = fieldConstraints[column.field];
        switch (constraints?.type) {
          case 'number':
            if (isNaN(value)) {
              schedule.imported = false;
              schedule.result = `Invalid ${column.headerText} input. Must be a number.`;
            } else if (constraints?.min && constraints?.max) {
              if (value < constraints?.min || value > constraints?.max) {
                schedule.imported = false;
                schedule.result = `Invalid ${column.headerText} input. Must be between ${constraints?.min} and ${constraints?.max}.`;
              }
            }
            break;
          case 'string':
            if (typeof value !== constraints?.type && value !== undefined) {
              schedule.imported = false;
              schedule.result = `Invalid ${column.headerText} input. Must be a string.`;
            } else if (constraints?.options && !constraints?.options.includes(value)) {
              schedule.imported = false;
              schedule.result = `Invalid ${column.headerText} input. Must be one of ${constraints?.options.join(', ')}.`;
            } else if (constraints?.format === 'HH:mm:ss' && !moment(value.replace(/\s/g, ''), 'HH:mm:ss', true).isValid()) {
              schedule.imported = false;
              schedule.result = `Invalid time input. Must be in HH:mm:ss format.`;
            } else if (!constraints?.optional && isInvalid(value)) {
              schedule.imported = false;
              schedule.result = `Invalid ${column.headerText} input.`;
            }
            break;
        }
      });
    });

    data.map((schedule) => {
      const sameDayBlocks = data.filter((o) => o.dayOfWeek === schedule.dayOfWeek && o.id !== schedule.id)
      const endTimeValue =
        schedule.endTime === '00:00:00' && schedule.startTime === '00:00:00'
          ? '24:00:00'
          : schedule.endTime;
      if (!isOverlap) {
        const overlapDay = sameDayBlocks.filter((block) => (block.startTime <= schedule.startTime && block.endTime > schedule.startTime)
          || (block.startTime >= schedule.startTime && block.startTime < endTimeValue)
          || (block.startTime >= schedule.startTime && schedule.startTime > endTimeValue) && (block.dayOfWeek === schedule.dayOfWeek && block.id !== schedule.id))
        if (overlapDay.length > 0) {
          schedule.imported = false;
          schedule.result = `Failed to import overlapping schedules.`
        }
      }
      if (schedule.titleGroupName) {
        const titleGroupInfo = retrieveTitleGroups(schedule);
        if (titleGroupInfo) {
          schedule.titleGroupID = titleGroupInfo.id
          schedule.titleGroupName = titleGroupInfo.name
        } else {
          schedule.imported = false;
          schedule.result = `Imported title group name does not exist.`
        }
      }
      if (schedule.seriesName) {
        const seriesInfo = retrieveSeries(schedule);

        if (seriesInfo) {
          schedule.seriesID = seriesInfo.id
          schedule.seriesName = seriesInfo.name
        } else {
          schedule.imported = false;
          schedule.result = `Imported series name does not exist.`
        }
      }
    })
    return data;
  }
  // Time deconstruction function of strings in the format of HH:MM:SS
  const deconstructTimeString = (time) => {
    // if minutes is 59 then add 1 on hour
    if (time.split(':')[1] === '59') {
      const hour = parseInt(time.split(':')[0]) + 1;
      return [hour.toString().length === 1 ? `0${hour.toString()}` : hour.toString(), '00', '00'];
    } else
      return time.split(':');
  };

  // Helper function to convert decimal time to HH:mm:ss format
  const convertToTime = (decimalTime) => {
    const duration = moment.duration(Math.floor(decimalTime * 86400000), 'milliseconds');
    const toTimeString = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
    // hour must have 00 digits
    const hour = deconstructTimeString(toTimeString)[0];
    const minute = deconstructTimeString(toTimeString)[1];
    const second = deconstructTimeString(toTimeString)[2];
    return `${hour}:${minute}:${second} `;
  };

  const architectureTab = sampleTemplate.includes('grade')
    ? 'Grading'
    : sampleTemplate.includes('minutage')
      ? 'Minutage'
      : 'Program';


  const getBlockName = (day, date) => {
    const hour = date.toTimeString().split(':')[0];
    const min = date.toTimeString().split(':')[1];

    const time = `${hour}:${min}`;
    return `${day}${time}`;
  };

  const removeImportAndResultFields = (data) => {
    let temp = [...data];
    let removedFailedImports = temp.filter((schedule) => schedule.imported)
    const cleanedData = removedFailedImports.map((schedule) => {
      schedule = Object.keys(schedule).reduce((object, key) => {
        if (key !== 'imported' && key !== 'result') {
          object[key] = schedule[key];
        }
        return object;
      }, {});
      return schedule;
    })
    return cleanedData;
  }
  const reconstructBlockName = (blockName) => {
    if (blockName?.length > 0) {
      const deconstructedTime = blockName.split(":");
      // if blockname contains 00:00:00 format instead of 00:00
      if (deconstructedTime && deconstructedTime.length === 3) {
        let temp = `${deconstructedTime[0]}:${deconstructedTime[1]}`
        return `${temp.slice(0, 3)}${temp.slice(-5)}`;
      } else {
        // From Mon108:00 to Mon08:00
        //Ensures that only the day and time is returned
        const removedWeek = `${blockName.slice(0, 3)}${blockName.slice(-5)}`
        return removedWeek;
      }
    } else return blockName
  }

  const constructData = React.useCallback((schedule, index) => {
    let tempSchedule = {};
    switch (architectureTab) {
      case 'Program':
        tempSchedule = {
          id: index,
          channelID: channelId,
          dayOfWeek: schedule.DayOfWeek,
          startTime: schedule.StartTime.replace(/\s/g, ''),
          endTime: schedule.EndTime.replace(/\s/g, ''),
          type: schedule?.Type,
          blockReference: reconstructBlockName(schedule?.BlockReference),
          genre: schedule?.Genre,
          maxCount: schedule?.MaxCount,
          availableDuration: schedule?.AvailableDuration,
          seriesID: schedule?.SeriesId,
          seriesName: schedule?.SeriesName,
          seasonID: schedule?.SeasonID,
          titleGroupID: schedule?.TitleGroupID,
          titleGroupName: schedule.TitleGroupName,
          layout: schedule?.Layout,
          sequential: schedule?.Sequential,
          link: schedule?.Link,
          blockName: reconstructBlockName(schedule?.BlockName),
          imported: true,
          result: "Success",
        }
        break;
      case 'Grading':
        tempSchedule = {
          id: index,
          channelID: channelId,
          dayOfWeek: schedule.DayOfWeek,
          startTime: schedule.StartTime.replace(/\s/g, ''),
          endTime: schedule.EndTime.replace(/\s/g, ''),
          grade: schedule.Grade,
          value: 0,
          minutage: schedule?.Minutage ?? 0,
          imported: true,
          result: "Success",
        }
        break;
      case 'Minutage':
        tempSchedule = {
          id: index,
          channelID: channelId,
          dayOfWeek: schedule.DayOfWeek,
          startTime: schedule.StartTime.replace(/\s/g, ''),
          endTime: schedule.EndTime.replace(/\s/g, ''),
          grade: schedule?.Grade ?? 0,
          value: 0,
          minutage: parseFloat(schedule.Minutage),
          imported: true,
          result: "Success",
        }
        break;

    }
    return tempSchedule;
  }, [architectureTab, channelId, period])

  const uploadFile = async (file, fileExtension, overwrite) => {
    setIsLoading(true);
    const reader = new FileReader();
    // Extract the data from the file
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      //Validate the file content
      if (!validateImportedFile(jsonData, importResultColumns)) {
        setErrorMessage(
          'Invalid file format. Please use files in the suggested format above (see sample template)'
        );
        setConfirmModal(false);
        setIsDirty(false);
        setUploadSuccess(false);
        setIsLoading(false);
        return;
      }
      // StartTime and EndTime values are either in decimal time format or 00:00 from the file
      // Convert StartTime and EndTime to time format of 00:00:00
      const importedData = jsonData.map((schedule, index) => {
        if (schedule.StartTime !== undefined && schedule.EndTime !== undefined) {
          // from decimal time to HH:mm:ss
          if (typeof schedule.StartTime === 'number') {
            schedule.StartTime = convertToTime(schedule.StartTime);
            schedule.EndTime = convertToTime(schedule.EndTime);
          }
          else {
            if (schedule.StartTime.split(':').length === 2 && schedule.EndTime.split(':').length === 2) {
              // Add seconds to the time
              schedule.StartTime = `${schedule.StartTime}:00`;
              schedule.EndTime = `${schedule.EndTime}:00`;
            }
          }
          const date = calculateDates(
            schedule.DayOfWeek,
            schedule.Week ?? 1,
            schedule.StartTime,
            schedule.EndTime,
            {
              startDate: period.startTime,
              endDate: period.endTime
            })['startTime'];

          schedule.BlockName = getBlockName(
            getDayOfWeek(date).name,
            date);
          return constructData(schedule, index + 1);
        }
      });

      // Validate the row values for the import result modal
      const validatedImportedData = validateRows(importedData);
      // Once validated, set the data to the state
      if (validatedImportedData.length > 0) {
        if ((schedules?.length > 0) && !overwrite) {
          setConfirmModal(true);
          setIsLoading(false);
          setIsDirty(false);
          setUploadedList();
        } else {
          setUploadedList(validatedImportedData);
          setIsDirty(true);
          setUploadSuccess(true);
          setConfirmModal(false);
          setIsLoading(false);
          setSchedules(removeImportAndResultFields(validatedImportedData));
        }
      }
    }

    reader.readAsArrayBuffer(file);
  };

  const handleOnImportFile = () => {
    const acceptableFiles = ['xlsx', 'xls', 'csv'];
    const fileExtension = selectedFile.name.split('.').pop();
    if (acceptableFiles.includes(fileExtension)) {
      if (scheduledata.length > 0) setConfirmModal(true);
      else uploadFile(selectedFile, fileExtension, false);
    } else {
      setErrorMessage(
        'Uploaded file type is not supported. Please use files in the following format (xlsx, csv)'
      );
    }
  };

  const handleOverwrite = () => {
    const fileExtension = selectedFile.name.split('.').pop();
    uploadFile(selectedFile, fileExtension, true);
  };

  return isLoading ? (
    <ImportLoadingModalComponent headerComponent={loadingHeaderComponent} />
  ) : uploadSuccess ? (
    <ImportResultModalComponent
      uploadedList={uploadedList}
      closeModal={closeModal}
      scheduleInfo={scheduleInfo}
      importResultColumns={importResultColumns}
      importResultFileTitle={importResultFileTitle}
    />
  ) : confirmModal ? (
    <ImportConfirmationModalComponent
      show={confirmModal}
      onHide={closeModal}
      onProceed={handleOverwrite}
      message="A list already exists for this channel. Importing overwrites all existing data for this channel."
    />
  ) : (
    <ImportFileModalComponent
      closeModal={closeModal}
      selectedFile={selectedFile}
      setSelectedFile={(e) => {
        setSelectedFile(e);
        setErrorMessage('');
      }}
      handleOnImportFile={handleOnImportFile}
      errorMessage={errorMessage}
      setErrorMessage={(e) => setErrorMessage(e)}
      sampleTemplate={sampleTemplate}
    />
  );
}
