/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable indent */
import React from 'react';
import { Scheduler } from 'op2mise-react-widgets';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';
import {
  ImportGradeArchitectureAPI,
  GetGradeArchitectureAPI,
} from "api";
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import { formatDateWithMoment } from 'utils';
import { ImportComponent } from './modals/ImportComponent';
import Button from 'shared/components/button/Button';
import { getMondayOfTheWeek } from 'utils';
import { addDaysToDate } from 'utils';
import ModifyGradingAndMinutage from './modals/ModifyGradingAndMinutage';
import { getMondayZeroToSixSchedules } from './utils/functions/getMondayZeroToSixSchedules';
import useStore from 'store/AccountStore';

export function CAGrading({
  schedulerProps,
  channelInfo,
  calculateDates,
  isDirty,
  setIsDirty,
  startDate,
  endDate,
  success,
  setGrading,
  handleSaveGradeArchitecture }) {
  const { user } = useStore((state) => state);
  const instance = new Internationalization();
  const [schedules, setSchedules] = React.useState([{}]);
  const [openForm, setOpenForm] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshSchedules, setRefreshSchedules] = React.useState(false);
  const { channelId, channelName } = channelInfo;
  const blockData = React.useRef({});
  const grade = React.useRef(0);
  const [unsavedModifiedData, setUnsavedModifiedData] = React.useState([]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isClockTypeBroadcast, setClockTypeBroadcast] = React.useState(user?.clockType === 'Broadcast');

  // Getting date range of today and tomorrow
  // NOTE: Use addDaysToDate from date.utils.js
  const tomorrow = moment()
    .add(1, 'days')
    .toDate();

  const period = `${formatDateWithMoment(
    new Date().toDateString(),
  )} - ${formatDateWithMoment(tomorrow)}`;

  // Date Header Template
  const dateHeaderTemplate = (args) => {
    const day = instance.formatDate(args.date, { skeleton: 'E' });
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className="e-header-day"
          style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
        >
          {day}
        </div>
      </div>
    );
  };

  const eventFields = (args) => ({
    Grade: args.grade,
    Id: args.id,
    ProgramType: args.programType,
    StartTime: calculateDates(args.dayOfWeek, args.week ?? 1, args.startTime, args.endTime, { startDate: startDate, endDate: endDate })['startTime'],
    EndTime: calculateDates(args.dayOfWeek, args.week ?? 1, args.startTime, args.endTime, { startDate, endDate })['endTime'],
    DayOfWeek: args.dayOfWeek,
  })

  const eventTemplate = (args) => {
    const { Grade, Id, ProgramType, StartTime, EndTime } = args;
    return (
      <div
        onContextMenu={() => {
          blockData.current = { ...args };
        }}
        className={`e-schedule-custom-event e-schedule-grading e-schedule-grade-${Grade}`}
      >
        <div className="e-schedule-custom-event-title e-schedule-grading-title">
          {Grade}
        </div>
      </div>
    );
  };
  const undoItem = () => {
    if (unsavedModifiedData && unsavedModifiedData.length > 0) {
      // Get last action in unsavedModifiedData
      const highestIndex = unsavedModifiedData.length - 1;
      // Revert the last action and remove from array
      const lastAction = unsavedModifiedData[highestIndex];
      const { action, grading } = lastAction;
      if (action === 'import') {
        // If action is import, remove all imported data
        unsavedModifiedData.splice(highestIndex, 1);
        if (unsavedModifiedData.length === 0) {
          setIsDirty(false);
        }
        setUnsavedModifiedData([...unsavedModifiedData]);
        setSchedules({ grading: grading });
        setGrading({ grading: grading });
      } else {
        // If action is modify, remove the last action from the array
        const revertData = grading.map((schedule) => {
          if (schedule.id === lastAction.Id) {
            return { ...schedule, grade: lastAction.Grade };
          }
          return schedule;
        })
        const removedItem = unsavedModifiedData.splice(highestIndex, 1);
        if (unsavedModifiedData.length === 0) {
          setIsDirty(false);
        }
        setUnsavedModifiedData([...unsavedModifiedData]);
        setSchedules({ grading: revertData });
        setGrading({ grading: revertData });
      }
    } else {
      setIsDirty(false);
    }
  };

  const customFooter = () => {
    return (
      <div style={{ marginTop: '15px', float: 'right' }}>
        <Button
          text="Save"
          style={{ marginLeft: '10px' }}
          onClick={() => { handleSaveGradeArchitecture(schedules), setRefreshSchedules(true) }}
          disabled={!isDirty}
        />
      </div>
    );
  };

  const props = React.useMemo(
    () => ({
      ...schedulerProps,
      dataSource: schedules,
      eventLookupList: 'grading',
      loading: isLoading,
      suppressExporting: true,
      suppressQuickInfo: true,
      suppressTooltip: true,
      dateHeaderTemplate,
      eventTemplate,
      eventFields,
      onImport: () => {
        // Put your import logic here
        setOpenForm('IMPORT');
      },
      onSlotModified: () => setIsDialogOpen(true),
      schedule: { start: startDate, end: endDate },
      settings: ['SLOT DURATION', 'SLOT INTERVAL'],
      timeoutDelay: 800,
      actionButtonPanel: customFooter,
      heightBuffer: 230,
      onUndo: undoItem,
    }),
    [schedules, startDate, endDate],
  );

  const cleanOutInvalidGrades = (data) => {
    return data.filter((schedule) => schedule.grade !== null && schedule.grade !== undefined && schedule.grade !== 0);
  }

  const modalProps = React.useMemo(() => ({
    isOpen: isDialogOpen,
    closeModal: () => { setIsDialogOpen(false) },
    handleUpdate: (newGrade) => {
      grade.current = newGrade;
    },
    blockData: blockData.current,
    type: 'grade',
  }), [blockData.current, isDialogOpen]);

  const RenderScheduler = React.useCallback(() => {
    return (channelId === 0) && !channelName
      ? null
      : (<Scheduler {...props} />)
  }, [props]);

  React.useEffect(() => {
    // Change between Standard and Broadcast clocktype
    setClockTypeBroadcast(user?.clockType === 'Broadcast');
  }, [user]);

  React.useEffect(() => {
    setIsDialogOpen(false);
    setUnsavedModifiedData([]);
    blockData.current = {};
    grade.current = 0;
  }, []);

  const validateModfiedGrading = (newGrade) => {
    // Check if new grade is a number and not a decimal
    if (newGrade && !isNaN(newGrade) && !newGrade.toString().includes('.')) {
      return newGrade;
    }
    // If new grade is not a number or is a decimal, set grading to the base value
    const baseGrade = newGrade.toString().split('.')[0];
    return parseInt(baseGrade);
  }

  React.useEffect(() => {
    // Disables 'Save' button when there are no unsaved changes
    if (unsavedModifiedData.length === 0)
      setIsDirty(false);

  }, [unsavedModifiedData])

  React.useEffect(() => {
    if (grade.current > 0) {
      const validGrade = validateModfiedGrading(grade.current);
      const copySchedules = [...schedules.grading];
      const updatedSchedules = copySchedules.map((schedule) => {
        if (schedule.id === blockData.current.Id) {
          if (grade.current !== blockData.current.Grade) {
            setUnsavedModifiedData([
              ...unsavedModifiedData,
              {
                ...blockData.current,
                action: 'modify',
                grading: schedules.grading
              }]);
            setIsDirty(true);
          }
          return { ...schedule, grade: grade.current };
        }
        return schedule;
      });
      if (updatedSchedules.length > 0) {
        setSchedules({ grading: updatedSchedules });
        setGrading({ grading: updatedSchedules });
        grade.current = 0;
      }
    }
  }, [grade.current]);

  React.useEffect(() => {
    if (channelId || refreshSchedules) {
      GetGradeArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          const validGrades = cleanOutInvalidGrades(response);
          setUnsavedModifiedData([])
          setSchedules({
            grading:
              isClockTypeBroadcast
                ? getMondayZeroToSixSchedules([...validGrades], startDate, endDate)
                : [...validGrades]
          });
          setGrading({
            grading: isClockTypeBroadcast
              ? getMondayZeroToSixSchedules([...validGrades], startDate, endDate)
              : [...validGrades]
          });
          setRefreshSchedules(false);
        },
        setLoader: setIsLoading,
      });
    }
  }, [channelId, refreshSchedules]);

  return (
    <>
      {' '}
      {openForm === 'IMPORT' && (
        <ImportComponent
          closeModal={() => setOpenForm(false)}
          setIsDirty={setIsDirty}
          scheduleInfo={{ ...channelInfo, period }}
          schedulerData={{
            schedules: schedules.grading,
            period: { startTime: startDate, endTime: endDate },
            scheduleInfo: {
              ...channelInfo,
              period,
            },
          }}
          sampleTemplate="gradeImportTemplate"
          importResultColumns={[{
            field: 'dayOfWeek',
            headerText: 'Day of Week',
          }, {
            field: 'startTime',
            headerText: 'Start Time',
          }, {
            field: 'endTime',
            headerText: 'End Time',
          }, {
            field: 'grade',
            headerText: 'Grade',
          }]}
          importResultFileTitle="grade_import_result"
          setSchedules={(data) => {
            // Include import action to undo list
            setUnsavedModifiedData([...unsavedModifiedData, { grading: schedules.grading, action: 'import' }]);
            setSchedules({
              grading: isClockTypeBroadcast
                ? getMondayZeroToSixSchedules(data, startDate, endDate)
                : data
            })
            setGrading({
              grading: isClockTypeBroadcast
                ? getMondayZeroToSixSchedules(data, startDate, endDate)
                : data
            });
            setIsLoading(false);
          }}
        />
      )}
      {isDialogOpen && (
        <ModifyGradingAndMinutage
          {...modalProps}
        />
      )}
      {
        isLoading
          ? <div style={{ height: 'calc(100vh - 100px)' }}>
            <BootstrapSpinner />
          </div>
          :
          <>
            <RenderScheduler />
          </>
      }
    </>
  );
}

export default CAGrading;