import React, { useEffect } from "react";
import { Modal } from 'react-bootstrap';
import { Dialog } from "@progress/kendo-react-dialogs";
import CustomTimeInput from "shared/components/custom-time-input/CustomTimeInput";
import ReccurencePatternComponent from "components/schedule/react-scheduler/forms/ReccurencePatternComponent";
import {
  getWeekNumber,
  formatDateWithMoment,
  addDaysToDate,
  durationStringToMinutes,
  computeTimeDifference,
  getMondayOfTheWeek,
  getDayOfWeek
} from "utils";
import Button from "shared/components/button/Button";
import Select from "shared/components/select/Select";
import Switch from "shared/components/switch/Switch";
import moment from "moment";
import { Internationalization } from "@syncfusion/ej2-base";
import style from "../architecture.module.css";
import { Constants } from "../helper/constants";
import { number } from "prop-types";
import { start } from "@progress/kendo-react-dateinputs";
import { get } from "jquery";
import OpTimeInput from "op2mise-react-widgets/OpTimeInput/OpTimeInput";

const DuplicateComponent = (props) => {
  const {
    selectedSlot,
    onClose,
    numberOfWeeks,
    onPaste,
    schedules,
    startDate,
    endDate,
    setNumberOfWeeks,
    channelId,
    calculateDates,
    referenceList
  } = props;
  const [weekNumber, setWeekNumber] = React.useState(1);
  const [dayOfWeek, setDayOfWeek] = React.useState({ id: 1, name: "Monday" });
  const [startTime, setStartTime] = React.useState(selectedSlot.StartTime);
  const [endTime, setEndTime] = React.useState(selectedSlot.EndTime);
  const [hasLink, setHasLink] = React.useState(selectedSlot.Link ?? selectedSlot.BlockReference);
  const [includeLinkedBlocks, setIncludeLinkedBlocks] = React.useState(false);
  const [blockReference, setBlockReference] = React.useState("");
  const [recurrencePattern, setRecurrencePattern] = React.useState({});
  const { weekdays } = Constants;
  const [linkReference, setLinkReference] = React.useState(referenceList.find((item) => item.blockName === selectedSlot.BlockReference) ?? {});
  const [errorMessage, setErrorMessage] = React.useState(null);
  const instance = new Internationalization();

  // Input Component
  const InputComponent = ({ label, input, gap }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        gap: gap ?? 20,
      }}
    >
      {label && <p style={{ whiteSpace: "nowrap", margin: '0' }}>{label}</p>}
      {input}
    </div>
  );


  const generateUID = () => {
    return crypto.randomUUID();
  }

  const constructData = (args, data) => {
    return {
      ...args,
      ...data,
      id: generateUID(),
      startTime: data.startTime.toTimeString().split(" ")[0],
      endTime: data.endTime.toTimeString().split(" ")[0],
    };
  };

  // Checks overlapping schedules among newly copied schedules
  const hasOverlapping = (rp) => {
    let isOverlap = false;
    rp.map((value) => {
      const sameDay = rp.filter(
        (o) => o.id !== value.id && (o.dayOfWeek === value.dayOfWeek) && (o.week === value.week));
      if (sameDay) {
        const overlapSameDay = sameDay.some(
          (o) => o.startTime <= value.startTime && o.endTime > value.startTime
        ) ||
          sameDay.some(
            (o) => o.startTime >= value.startTime && o.startTime < value.endTime
          ) ||
          sameDay.some(
            (o) => o.startTime >= value.startTime && value.startTime > value.endTime
          ) ||
          sameDay.some(
            (o) => o.startTime === value.startTime && o.endTime === value.endTime
          );
        if (overlapSameDay) {
          isOverlap = true;
        }
      }
    })
    return isOverlap;
  }

  // Check if there is an overlap in the schedules.programmes
  const checkIfScheduleExists = (tempList) => {
    let isOverlap = false;
    const allBlocks = schedules.programmes;
    [tempList].map((value) => {
      const sameDayBlocks = allBlocks.filter(
        (o) =>
          o.dayOfWeek === value.dayOfWeek &&
          o.week === value.week &&
          o.id != value.id
      );

      if (!isOverlap) {
        const overlapSameDay =
          sameDayBlocks.length == 0
            ? false
            : sameDayBlocks.some(
              (o) => o.startTime <= value.startTime && o.endTime > value.startTime
            ) ||
            sameDayBlocks.some(
              (o) => o.startTime >= value.startTime && o.startTime < value.endTime
            ) ||
            sameDayBlocks.some(
              (o) => o.startTime >= value.startTime && value.startTime > value.endTime
            )
        if (overlapSameDay) {
          isOverlap = true;
        }

        //check before day if not first day
        if (!isOverlap && !(value.dayOfWeek === 1 && value.week === 1)) {
          const dayBefore =
            parseInt(value.dayOfWeek) === 1 ? 7 : parseInt(value.dayOfWeek) - 1;
          const weekBefore =
            dayBefore === 7 ? parseInt(value.week) - 1 : value.week;
          const beforeDayBlocks = allBlocks
            .filter((o) => o.dayOfWeek === dayBefore && o.week === weekBefore)
            .sort((a, b) =>
              a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0
            );
          if (beforeDayBlocks.length > 0) {
            //get latest block
            const overlapDayBefore = beforeDayBlocks.some(
              (o) =>
                o.endTime > value.startTime &&
                o.endTime > value.startTime &&
                o.startTime > o.endTime
            );
            if (overlapDayBefore) isOverlap = true;
          }
        }

        //check after day if not last day
        const overnightBlock = value.startTime > value.endTime;
        if (
          !(value.dayOfWeek === 7 && value.week === numberOfWeeks) &&
          overnightBlock
        ) {
          const dayAfter =
            parseInt(value.dayOfWeek) === 7 ? 1 : parseInt(value.dayOfWeek) + 1;
          const weekAfter = dayAfter === 1 ? parseInt(value.week) + 1 : value.week;
          const afterDayBlocks = allBlocks
            .filter((o) => o.dayOfWeek === dayAfter && o.week === weekAfter)
            .sort((a, b) =>
              a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0
            );
          if (afterDayBlocks.length > 0) {
            //get first block
            const overlapDayAfter = afterDayBlocks.some(
              (o) => o.startTime < value.endTime && o.startTime < o.endTime
            );
            if (overlapDayAfter) isOverlap = true;
          }
        }
      }
    });
    return isOverlap;
  };

  const updateData = (date, daysToAdd, endTime) => {
    let tempStartDate = addDaysToDate(date, daysToAdd);
    let tempEndDate = addDaysToDate(endTime, daysToAdd);
    let day = getDayOfWeek(tempStartDate)
    return {
      dayOfWeek: day.id,
      blockName: getBlockName(tempStartDate),
      startTime: tempStartDate,
      endTime: tempEndDate
    };
  };

  const getBlockName = (date, newWeek) => {
    const week = getWeekNumber(date, startDate, endDate);
    const day = getDayOfWeek(date);
    const time = date.toTimeString().split(" ")[0];
    const hours = time.split(":")[0];
    const minutes = time.split(":")[1];
    return `${day.name}${newWeek ?? week}${hours}:${minutes}`;
  }

  // Fetch link reference data from schedules.programmes
  const getLinkReferenceData = (id, blockName) => {
    return schedules.programmes.find((schedule) =>
      schedule.id === id &&
      schedule.blockName === blockName
    );
  }

  const findBlockReference = (blockName) => {
    return schedules.programmes.find((schedule) => schedule.blockReference === blockName && schedule.link);
  }

  // blockName value: Tue112:00
  const filterAllWithSameBlockReference = (blockName) => {
    // Returns all blocks with the same block reference value of Tue112:00
    return schedules.programmes.filter((schedule) => schedule.blockReference === blockName && schedule.link);
  }

  const getLinkedBlocks = (blockName) => {
    let linkedBlocks = [];
    let foundBlock = findBlockReference(blockName);
    let tempBlock = foundBlock;
    if (foundBlock) {
      linkedBlocks.push(foundBlock);
      do {
        foundBlock = findBlockReference(foundBlock.blockName);
        // if tempBlock is the same as the previous foundBlock, break the loop
        if (tempBlock === foundBlock) break;
        linkedBlocks.push(foundBlock);
      } while (foundBlock?.blockReference)
    }
    return linkedBlocks.filter((block) => block !== undefined);
  }

  const fetchAllLinkedBlocks = React.useCallback((
    blockName,
    recurrencePattern,
    selectedBlock,
    startTime,
    endTime
  ) => {
    const schedulesWithSameBlockReference = filterAllWithSameBlockReference(blockName);
    let all = []
    if (schedulesWithSameBlockReference.length > 1) {
      schedulesWithSameBlockReference.map((schedule) => {
        let foundLinkedBlocks = getLinkedBlocks(schedule.blockName);
        all = [all, ...foundLinkedBlocks, schedule].flat().filter((block) => block.id);
      })
    } else if (schedulesWithSameBlockReference.length === 1) {
      let linkedBlock = schedulesWithSameBlockReference[0];
      let foundLinkedBlocks = getLinkedBlocks(linkedBlock.blockName);
      all = [...foundLinkedBlocks, linkedBlock];
    }
    let collectLinkedBlocks = [];
    let linkReferencedBlock = getLinkReferenceData(linkReference.id, linkReference.blockName);
    const withDifferentWeeks = all.some((block) => hasLink ? block.week !== linkReferencedBlock.week : block.week !== selectedBlock.Week);
    if (all.length > 0) {
      let blockReference = all.blockName ?? all[0].blockName;
      switch (recurrencePattern?.reccurencePatternId) {
        case 2:
          if (recurrencePattern.week.name === 'Every Week') {
            // const weeks = selectedBlock.Week < numberOfWeeks
            //   ? numberOfWeeks - selectedBlock.Week
            //   : numberOfWeeks;

            collectLinkedBlocks = [...Array(numberOfWeeks).keys()].map((week) => {
              let data = all.map((block) => {
                let date = calculateDates(
                  block.dayOfWeek,
                  block.week,
                  startTime.toTimeString().split(" ")[0],
                  endTime.toTimeString().split(" ")[0],
                  { startDate: startDate, endDate: endDate })
                  .startTime;

                let constructedData = {
                  ...block,
                  id: generateUID(),
                  startTime: startTime.toTimeString().split(" ")[0],
                  endTime: endTime.toTimeString().split(" ")[0],
                  blockName: getBlockName(date, block.week + week),
                  blockReference: blockReference,
                  week: block.week + week,
                }
                blockReference = constructedData.blockName;
                return constructedData;
              })
              return data;
            })
          } else {
            if (withDifferentWeeks) {
              collectLinkedBlocks = all.map((block) => {
                let newWeek = (recurrencePattern.week.id + block.week) - 1;
                let date = calculateDates(
                  block.dayOfWeek,
                  block.week,
                  startTime.toTimeString().split(" ")[0],
                  endTime.toTimeString().split(" ")[0],
                  { startDate: startDate, endDate: endDate })
                  .startTime;
                let constructedData = {
                  ...block,
                  id: generateUID(),
                  startTime: startTime.toTimeString().split(" ")[0],
                  endTime: endTime.toTimeString().split(" ")[0],
                  blockName: getBlockName(date, newWeek),
                  blockReference: blockReference,
                  week: newWeek,
                }
                blockReference = constructedData.blockName;
                return constructedData;
              });
            } else {
              collectLinkedBlocks = all.map((block) => {
                let date = calculateDates(
                  block.dayOfWeek,
                  block.week,
                  startTime.toTimeString().split(" ")[0],
                  endTime.toTimeString().split(" ")[0],
                  { startDate: startDate, endDate: endDate })
                  .startTime;
                let constructedData = {
                  ...block,
                  id: generateUID(),
                  startTime: startTime.toTimeString().split(" ")[0],
                  endTime: endTime.toTimeString().split(" ")[0],
                  blockName: getBlockName(date, recurrencePattern.week?.id),
                  blockReference: blockReference,
                  week: recurrencePattern.week.id,
                }
                blockReference = constructedData.blockName;
                return constructedData;
              });
            }
          }
          break;
        case 3:
          if (recurrencePattern.everyWeekInterval && recurrencePattern.days) {
            const { everyWeekInterval, days } = recurrencePattern;
            [...Array(parseInt(everyWeekInterval)).keys()].map((week) => {
              if (!withDifferentWeeks) {
                collectLinkedBlocks = all.map((block) => {
                  let newWeek = (week + block.week);
                  let date = calculateDates(
                    block.dayOfWeek,
                    block.week,
                    block.startTime,
                    block.endTime,
                    { startDate: startDate, endDate: endDate })
                    .startTime;
                  let constructedData = {
                    ...block,
                    id: generateUID(),
                    startTime: startTime.toTimeString().split(" ")[0],
                    endTime: endTime.toTimeString().split(" ")[0],
                    blockName: getBlockName(date, newWeek),
                    blockReference: block.blockName,
                    week: newWeek,
                  }
                  blockReference = constructedData.blockName;
                  return constructedData;
                });
              }
            })
          }
          break;
        default:
          // After link
          collectLinkedBlocks = all.map((block) => {
            let date = calculateDates(
              block.dayOfWeek,
              block.week,
              startTime.toTimeString().split(" ")[0],
              endTime.toTimeString().split(" ")[0],
              { startDate: startDate, endDate: endDate })
              .startTime;
            let constructedData = {
              ...block,
              id: generateUID(),
              startTime: startTime.toTimeString().split(" ")[0],
              endTime: endTime.toTimeString().split(" ")[0],
              blockName: getBlockName(date, getWeekNumber(date, startDate, endDate)),
              blockReference: blockReference,
              week: getWeekNumber(date, startDate, endDate),
            }
            blockReference = constructedData.blockName;
            return constructedData;
          });
          break;
      }
    }
    return collectLinkedBlocks;
  }, [hasLink, linkReference, startTime, endTime]);

  const combineLinkedBlocksAndPatterns = React.useCallback((patterns, linkedBlocks) => {
    if (linkedBlocks.length > 0) {
      //merge linked blocks and patterns
      const allBlocks = linkedBlocks.concat(patterns).flat(1);
      //sort all blocks by week, dayOfWeek, startTime
      const sortedBlocks = allBlocks.sort((a, b) => {
        if (a.week > b.week) return 1;
        if (a.week < b.week) return -1;
        if (a.dayOfWeek > b.dayOfWeek) return 1;
        if (a.dayOfWeek < b.dayOfWeek) return -1;
        if (a.startTime > b.startTime) return 1;
        if (a.startTime < b.startTime) return -1;
        return 0;
      });

      // Assigned block reference as blockName of previous block
      let blockReference = hasLink ? linkReference.blockName : '';
      sortedBlocks.map((block) => {
        if (block.blockReference) {
          block.link = blockReference?.length > 0 ? true : false;
          block.blockReference = blockReference;
        }
        blockReference = block.blockName;
      });
      return sortedBlocks.flat(1);
    } else {
      return patterns;
    }
  }, [hasLink, linkReference]);

  // For Link Reference Component
  const linkCopiedBlock = React.useCallback((
    args,
    linkReferenceValue,
    daysToAdd,
    dayOfWeek,
    week
  ) => {
    // Extract link reference data
    const linkedBlock = getLinkReferenceData(linkReferenceValue.id, linkReferenceValue.blockName);
    let linkedBlockEndDate = calculateDates(
      dayOfWeek ?? linkedBlock.dayOfWeek,
      week ?? linkedBlock.week,
      linkedBlock.startTime,
      linkedBlock.endTime,
      { startDate: startDate, endDate: endDate })
      .endTime;
    const timeDifference = computeTimeDifference(
      new Date(selectedSlot.StartTime),
      new Date(selectedSlot.EndTime)
    );
    const durationInMinutes = durationStringToMinutes(timeDifference).split(" ")[0];

    const newEndDateTime = moment(linkedBlockEndDate).add(durationInMinutes, 'minutes')._d
    if (newEndDateTime) setEndTime(newEndDateTime)
    let day = getDayOfWeek(linkedBlockEndDate)
    return {
      week: week,
      blockName: getBlockName(linkedBlockEndDate, week),
      startTime: linkedBlockEndDate,
      blockReference: linkedBlock.blockName,
      endTime: endTime ?? newEndDateTime,
      dayOfWeek: dayOfWeek ?? day.id,
      link: true
    }
  }, [startTime, endTime])

  const getPatternEverySelectedDay = React.useCallback((
    recurrencePattern,
    block,
    startTime,
    endTime
  ) => {
    const { intervalValue, intervalId, week } = recurrencePattern;
    const recurrenceWeek = week;
    let blockReference = hasLink ? linkReference.blockName : '';
    let daysToAdd = 0;
    let data = {};
    let collectWeeklySchedules = [];
    if (recurrenceWeek.name === 'Every Week') {
      collectWeeklySchedules = [...Array(numberOfWeeks).keys()].map((week) => {
        if (block.dayOfWeek < intervalValue) daysToAdd = intervalValue - block.dayOfWeek;
        else if (block.dayOfWeek === intervalValue) daysToAdd = 0;
        else daysToAdd = 7 + intervalValue - block.dayOfWeek;
        data = updateData(
          startTime,
          daysToAdd,
          endTime
        );
        if (hasLink && linkReference?.id) {
          data = linkCopiedBlock(block, linkReference, daysToAdd, intervalValue, week + 1);
        } else {
          data = {
            ...data,
            // blockReference: blockReference,
            week: week + 1,
            link: hasLink,
          }
        }
        data = { ...data, blockReference: blockReference }
        blockReference = data.blockName;
        return constructData(block, data);
      });
      return collectWeeklySchedules;
    } else {
      if (block.dayOfWeek < intervalValue) daysToAdd = intervalValue - block.dayOfWeek;
      else daysToAdd = 7 + intervalValue - block.dayOfWeek;
      data = updateData(startTime, daysToAdd, endTime);
      if (hasLink && linkReference?.id) {
        data = linkCopiedBlock(block, linkReference, daysToAdd, intervalValue, recurrenceWeek.id);
      } else {
        data = {
          ...data,
          blockName: getBlockName(addDaysToDate(startTime, daysToAdd), recurrenceWeek.id),
          blockReference: blockReference,
          week: recurrenceWeek.id,
          link: hasLink,
        }
      }
      data = { ...data, blockReference: blockReference }
      return constructData(block, data);
    }
  }, [linkReference, startTime, endTime, hasLink])

  const getPatternEveryWeekday = React.useCallback((
    recurrencePattern,
    block
  ) => {
    const { intervalValue, intervalId, week } = recurrencePattern;
    const recurrenceWeek = week;
    const hours = startTime.getHours();
    const minutes = startTime.getMinutes();
    const seconds = startTime.getSeconds();
    let mondayDate = getMondayOfTheWeek(startTime);
    mondayDate = moment(mondayDate).hour(hours).minute(minutes)._d
    const weekdaysIndex = [0, 1, 2, 3, 4]
    let blockReference = "";
    let data = {};
    let collectWeeklySchedules = [];
    let collectWeekdays = [];

    if (recurrenceWeek.name === 'Every Week') {
      collectWeeklySchedules = [...Array(numberOfWeeks).keys()].map((week) => {
        collectWeekdays = weekdaysIndex.map((day) => {
          data = updateData(mondayDate, day, endTime);
          if (hasLink && linkReference?.id) data = linkCopiedBlock(block, linkReference, 0, day + 1, week + 1);
          data = {
            ...data,
            blockName: getBlockName(addDaysToDate(mondayDate, day), week + 1),
            blockReference: blockReference,
            week: week + 1,
            link: blockReference.length > 0 ? true : false
          }
          blockReference = data.blockName;
          return constructData(block, data);
        });
        return collectWeekdays;
      });
      return collectWeeklySchedules.flat();
    } else {
      collectWeekdays = weekdaysIndex.map((day) => {
        data = updateData(mondayDate, day, endTime);
        if (hasLink && linkReference?.id) data = linkCopiedBlock(block, linkReference, 0, day + 1, recurrenceWeek.id);
        data = {
          ...data,
          blockName: getBlockName(
            addDaysToDate(mondayDate, day), recurrenceWeek.id),
          blockReference: blockReference,
          week: recurrenceWeek.id,
          link: blockReference.length > 0 ? true : false
        }
        blockReference = data.blockName;
        return constructData(block, data);
      });
      return collectWeekdays;
    }
  }, [linkReference.blockName, startTime, endTime])

  const getDailyRecurrencePattern = React.useCallback((
    recurrencePattern,
    args,
    startTime,
    endTime
  ) => {
    let data = {};
    let blockReference = hasLink ? linkReference.blockName : '';
    const { intervalId, intervalValue, week } = recurrencePattern;
    const recurrenceWeek = week;
    switch (intervalId) {
      case 1:
        return getPatternEverySelectedDay(
          recurrencePattern,
          args,
          startTime,
          endTime
        );
      case 2: // Every weekdays
        return getPatternEveryWeekday(
          recurrencePattern,
          args,
          startTime,
          endTime
        );

      case 3: // Every weekend of Week n or every week
        let collectWeekends = [];
        let weekends = [6, 7];
        if (recurrenceWeek.name === "Every Week") {
          // for both saturday and sunday
          collectWeekends = [...Array(numberOfWeeks).keys()].map((week) => {
            let result = weekends.map((day) => {
              let daysToAdd = day - args.dayOfWeek;
              data = updateData(startTime, daysToAdd, endTime);
              let date = calculateDates(
                day,
                week + 1,
                startTime.toTimeString().split(" ")[0],
                endTime.toTimeString().split(" ")[0],
                { startDate: startDate, endDate: endDate }
              ).startTime;
              if (hasLink && linkReference?.id) {
                data = linkCopiedBlock(args, linkReference, 0, day, week + 1);
              } else {
                data = {
                  ...data,
                  blockName: getBlockName(date, week + 1),
                  week: week + 1,
                  link: blockReference.length > 0 ? true : false,
                }
              }
              data = { ...data, blockReference: blockReference }
              blockReference = data.blockName;
              return constructData(args, data);
            })
            return result;
          });

          collectWeekends = collectWeekends.flat();
          return collectWeekends
        } else {
          const dateInSelectedWeek = addDaysToDate(
            startTime,
            7 * recurrenceWeek.id //2 == 7*2 = 14
          );
          collectWeekends = weekends.map((day) => {
            let daysToAdd = day - args.dayOfWeek;
            // 
            data = updateData(dateInSelectedWeek, daysToAdd, endTime, recurrenceWeek.id);
            if (hasLink && linkReference?.id) {
              data = linkCopiedBlock(args, linkReference, daysToAdd, day, recurrenceWeek.id);
            } else {

              data = {
                ...data,
                blockName: getBlockName(addDaysToDate(dateInSelectedWeek, daysToAdd), recurrenceWeek.id),
                week: recurrenceWeek.id,
                link: blockReference.length > 0 ? true : false,
              }
            }
            data = { ...data, blockReference: blockReference }
            blockReference = data.blockName;
            return constructData(args, data);
          });
          return collectWeekends;
        }
    }
  }, [startTime, endTime, linkReference]);

  const getWeeklyRecurrencePattern = React.useCallback((
    everyWeekInterval,
    days,
    args,
    startTime,
    endTime,
  ) => {
    let blockReference = hasLink ? linkReference.blockName : '';
    if (everyWeekInterval > numberOfWeeks) setNumberOfWeeks(everyWeekInterval);

    let dayOfWeek = getDayOfWeek(startTime);
    let data = {};
    let collectWeeklySchedules = [];
    [...Array(parseInt(everyWeekInterval)).keys()].map((week) => {
      const tempDate = addDaysToDate(startTime, 7 * week);
      days.map((day) => {
        let daysToAdd = day - dayOfWeek.id;
        data = updateData(tempDate, daysToAdd, endTime, week + 1);
        if (hasLink && linkReference?.id) {
          data = linkCopiedBlock(args, linkReference, daysToAdd, day, week + 1);
        } else {
          data = {
            ...data,
            id: generateUID(),
            week: week + 1,
            link: blockReference.length > 0 ? true : false,
          }
        }
        data = { ...data, blockReference: blockReference }
        blockReference = data.blockName;
        collectWeeklySchedules.push(constructData(args, data));
      });
    });

    return collectWeeklySchedules;
  }, [linkReference]);

  const getReccurencePatternDetails = React.useCallback((recurrencePattern, args, startTime,
    endTime,) => {
    const { reccurencePatternId } = recurrencePattern;
    const schedule = schedules.programmes.find((schedule) => schedule.id === args.Id);
    const blockName = linkReference?.blockName ?? args.BlockName;
    const linkedBlocks = includeLinkedBlocks
      ? fetchAllLinkedBlocks(
        blockName,
        recurrencePattern,
        args,
        startTime,
        endTime
      )
      : []

    switch (reccurencePatternId) {
      case 2:
        const dailyPatterns = getDailyRecurrencePattern(
          recurrencePattern,
          schedule,
          startTime,
          endTime,
        );
        return combineLinkedBlocksAndPatterns(dailyPatterns, linkedBlocks);
      case 3:
        const { everyWeekInterval, days } = recurrencePattern;
        const weeklyPatterns = getWeeklyRecurrencePattern(
          parseInt(everyWeekInterval),
          days,
          schedule,
          startTime,
          endTime,
        );
        return combineLinkedBlocksAndPatterns(weeklyPatterns, linkedBlocks);
      default:
        if (hasLink) {
          setErrorMessage(null);
          const linkedBlock = getLinkReferenceData(linkReference.id, linkReference.blockName);
          let endDateTime = calculateDates(
            linkedBlock.dayOfWeek,
            linkedBlock.week,
            linkedBlock.startTime,
            linkedBlock.endTime,
            { startDate: startDate, endDate: endDate })
            .endTime;
          let afterLinkPattern = linkCopiedBlock(
            schedule,
            linkReference,
            0,
            getDayOfWeek(endDateTime).id,
            linkedBlock.week
          );
          return combineLinkedBlocksAndPatterns(
            constructData(schedule, afterLinkPattern),
            linkedBlocks
          );
        } else {
          setErrorMessage('Link reference is required for this recurrence pattern.');
          break;
        }
    }
  }, [
    includeLinkedBlocks,
    hasLink,
    linkReference,
    startTime,
    endTime,
  ]);

  const handleScheduleDetailChange = React.useCallback((args) => {
    setErrorMessage(null);
    const { recurrencePattern } = args;
    const rp = getReccurencePatternDetails(recurrencePattern, args, startTime, endTime);
    // Multiple days
    if (rp) {
      if (errorMessage) {
        return {}
      } else
        if (rp.length > 1) {
          if (rp.some((item) => checkIfScheduleExists(item)) || hasOverlapping(rp)) {
            setErrorMessage('Unable to copy block. A copied block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your copied block to avoid conflicts.');
            return {};
          } else {
            setErrorMessage(null);
            const data = rp.filter((item) => item !== undefined);
            onClose();
            return data.map((item) => item);
          }
        }
      // Single day
      if (rp.length === 1 || rp.id) {
        if (checkIfScheduleExists(rp.id ? rp : rp[0])) {
          setErrorMessage('Unable to copy block. A copied block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your copied block to avoid conflicts.');
          return {};
        } else {
          setErrorMessage(null);
          onClose();
          return rp.id ? rp : rp[0];
        }
      }
    }
    else {
      onClose();
      return {};
    }
  }, [
    startTime,
    endTime,
    errorMessage,
    recurrencePattern,
    includeLinkedBlocks
  ]);

  return (
    // <Dialog closeIcon={null}>
    <Modal
      show={true}
      onHide={() => onClose()}
      style={{ '--bs-modal-width': '840px' }}
    >
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          {" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 7,
              fontSize: "20px",
            }}
          >
            Duplicate Block
            <span>|</span>
            <div style={{ color: "#6D6D73" }}>
              {selectedSlot.BlockName} ({selectedSlot.Subject})
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: "25px 0",
              gap: 30,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 10,
                flex: 1,
              }}
            >
              <InputComponent
                label="Start Time"
                input={
                  <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
                    <OpTimeInput
                      value={startTime}
                      onChange={(e) => {
                        const date = moment(selectedSlot.StartTime);
                        const hour = e.getHours();
                        const mins = e.getMinutes();
                        setStartTime(date.hour(hour).minute(mins)._d);
                      }}
                      enabled={!hasLink}
                      width="full"
                      textAlign="center"
                    />
                    <Switch
                      label="Link"
                      labelStyle={{ color: "#6D6D73" }}
                      text={["No", "Yes"]}
                      active={hasLink}
                      disabled={false}
                      onSwitch={(e) => {
                        setHasLink(e);
                      }}
                      blurredOnInactive={false}
                    />
                  </div>
                }
              />
              <InputComponent
                label={"End Time"}
                input={
                  <div style={{ width: "250px" }}>
                    <OpTimeInput
                      value={endTime}
                      onChange={(e) => {
                        const date = moment(selectedSlot.EndTime);
                        const hour = e.getHours();
                        const mins = e.getMinutes();
                        setEndTime(date.hour(hour).minute(mins)._d);
                      }}
                      width="full"
                      textAlign="center"
                    />
                  </div>
                }
              />
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  flex: 1,
                }}
              >
                <InputComponent
                  label={"Link Reference"}
                  input={
                    <Select
                      text={linkReference?.name ?? "Please select an existing block"}
                      list={referenceList}
                      onSelect={(args) => {
                        setLinkReference(args)
                        const linkedBlock = getLinkReferenceData(args.id, args.blockName);
                        const newStartTime = calculateDates(
                          linkedBlock.dayOfWeek,
                          linkedBlock.week,
                          linkedBlock.startTime,
                          linkedBlock.endTime,
                          { startDate: startDate, endDate: endDate })
                          .endTime;

                        const timeDifference = computeTimeDifference(
                          new Date(selectedSlot.StartTime),
                          new Date(selectedSlot.EndTime)
                        );
                        const durationInMinutes = durationStringToMinutes(timeDifference).split(" ")[0];

                        const newEndTime = moment(newStartTime).add(durationInMinutes, 'minutes')._d
                        setStartTime(newStartTime);
                        setEndTime(newEndTime)
                      }}
                      width="250px"
                      disabled={!hasLink}
                    />
                  }
                />

                <InputComponent
                  input={
                    <Switch
                      label="Include linked blocks"
                      labelStyle={{ color: "#6D6D73" }}
                      text={["No", "Yes"]}
                      active={includeLinkedBlocks}
                      disabled={false}
                      onSwitch={(e) => {
                        setIncludeLinkedBlocks(e);
                      }}
                      blurredOnInactive={false}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <hr></hr>
          {/* Recurrence settings */}
          <div style={{ display: "flex", alignItems: "flex-start", flexDirection: 'column' }}>
            <ReccurencePatternComponent
              isRegular={true}
              patterns={
                hasLink ? [
                  { id: 2, name: 'Daily' },
                  { id: 3, name: 'Weekly' },
                  { id: 1, name: 'After Link' },
                ]
                  : [
                    { id: 2, name: 'Daily' },
                    { id: 3, name: 'Weekly' },
                  ]}
              onChangeRecurrence={(args) => {
                setRecurrencePattern(args)
              }}
              numberOfWeeks={numberOfWeeks}
              type="dropdown"
              hasLink={hasLink}
            />
            {errorMessage && (
              <div style={{ marginTop: "10px" }} class="errorValidation">
                {errorMessage}
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "12px",
            }}
          >
            <Button defaultBtn text="Cancel" onClick={onClose} />
            <Button
              text="Paste"
              type="button"
              onClick={() => {
                onPaste(
                  handleScheduleDetailChange({
                    ...selectedSlot,
                    EndTime: endTime,
                    StartTime: startTime,
                    isLinked: hasLink,
                    recurrencePattern: {
                      ...recurrencePattern,
                    },
                  })
                );

              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal >
  );
};

export default DuplicateComponent;
