import React from 'react'
import {
    TimePickerComponent,
    Inject,
    MaskedDateTime
} from '@syncfusion/ej2-react-calendars';
import { FormValidator } from '@syncfusion/ej2-inputs';
import './OpTimeInput.css';
import { Settings } from './constants';

const OpTimeInput = (props) => {
    const { widthOptions } = Settings;
    const {
        value,
        onChange,
        enabled = true,
        errorMessage,
        min, // default of 00:00, typeof Date
        max, // default of 00:00, typeof Date
        format = 'HH:mm',
        interval = 60,
        readonly = false,
        width = 'fit', // fit: 100, full: null
        textAlign = 'left' // left, center, right
    } = props;

    React.useEffect(() => {
        if (errorMessage) {
            const inputElement = document.querySelector('.op-timepicker input');
            if (inputElement) {
                const formObject = new FormValidator(inputElement, {
                    rules: {
                        required: true
                    }
                });
                formObject.validate();
            }
        }
    }, [errorMessage]);

    return (
        <form id='form-element'>
            <TimePickerComponent
                cssClass="op-timepicker"
                value={value}
                step={interval}
                format={format}
                min={min}
                max={max}
                onChange={(e) => onChange(e.target.value)}
                enabled={enabled}
                showClearButton={false}
                readonly={readonly}
                allowEdit={true}
                errorMessage={errorMessage}
                width={widthOptions[width]}
                enableMask={true} // only works when placeholder is not set
                maskPlaceholder={{ hour: 'HH', minute: 'mm' }}
                style={{ textAlign: textAlign }}
            >
                <Inject services={[MaskedDateTime]} />
            </TimePickerComponent>
        </form>
    )
}

export default OpTimeInput