export const Constants = {
    formTypes: {
        delete: 'DELETE',
        edit: 'EDIT',
        export: 'EXPORT',
        import: 'IMPORT',
        insert: 'INSERT',
        paste: 'PASTE',
    },
    modifiedDataType: {
        add: 'add',
        cut: 'cut',
        delete: 'delete',
    },
    weekdays: {
        Mon: 1,
        Tue: 2,
        Wed: 3,
        Thu: 4,
        Fri: 5,
        Sat: 6,
        Sun: 7,
    },
    programFields: [
        'dayOfWeek',
        'startTime',
        'endTime',
        'type',
        'blockReference',
        'genre',
        'maxCount',
        'availableDuration',
        'seriesID',
        'seriesName',
        'seasonNo',
        'titleGroupID',
        'titleGroupName',
        'layout',
        'sequential',
        'link',
        'blockName',
    ],
    gradingFields: [
        'dayOfWeek',
        'startTime',
        'endTime',
        'grade',
        'value',
    ],
    minutageFields: [
        'dayOfWeek',
        'startTime',
        'endTime',
        'value',
        'minutage',
    ],
    fieldConstraints: {
        week: {
            min: 1,
            max: 52,
            type: 'number',
        },
        dayOfWeek: {
            min: 1,
            max: 7,
            type: 'number',
        },
        startTime: {
            type: 'string',
            format: 'HH:mm:ss',
        },
        endTime: {
            type: 'string',
            format: 'HH:mm:ss',
        },
        grade: {
            min: 1,
            max: 10,
            type: 'number',
        },
        minutage: {
            type: 'float' || 'number',
        },
        imported: {
            type: 'boolean',
        },
        result: {
            type: 'string',
        },
        type: {
            type: 'string',
            options: ['Movies', 'Series', 'Placeholder', 'TitleGroup', 'Repeat'],
        },
        genre: {
            type: 'string',
            optional: true,
        },
    }

}