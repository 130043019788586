const SchedulerStorage = {
  tableHeaders: [
    {
      headerId: 1,
      headerName: 'Title Name',
      filter: {
        type: 1,
        queryNames: ['titleFilter', 'titleFilterValue'],
      },
      sortable: true,
    },
    {
      headerId: 2,
      headerName: 'Genre',
      filter: {
        type: 1,
        queryNames: ['genreFilter', 'genreFilterValue'],
      },
      sortable: true,
    },
    {
      headerId: 3,
      headerName: 'Duration',
      sortable: true,
    },
    {
      headerId: 4,
      headerName: 'Grade',
      filter: {
        type: 1,
        queryNames: ['gradeFilter', 'gradeFilterValue'],
      },
      sortable: true,
    },
    {
      headerId: 5,
      headerName: 'Certificate',
      filter: {
        type: 1,
        queryNames: ['certificateFilter', 'certificateFilterValue'],
      },
      sortable: true,
    },
    {
      headerId: 6,
      headerName: 'Year',
      filter: {
        type: 1,
        queryNames: ['yearFilter', 'yearFilterValue'],
        style: { marginLeft: '-25px' },
      },
      sortable: true,
    },
    {
      headerId: 7,
      headerName: 'View title',
      hiddenHeaderName: true,
      width: '42px',
    },
  ],

  months: [
    {
      id: 1,
      value: 'January',
    },
    {
      id: 2,
      value: 'February',
    },
    {
      id: 3,
      value: 'March',
    },
    {
      id: 4,
      value: 'April',
    },
    {
      id: 5,
      value: 'May',
    },
    {
      id: 6,
      value: 'June',
    },
    {
      id: 7,
      value: 'July',
    },
    {
      id: 8,
      value: 'August',
    },
    {
      id: 9,
      value: 'September',
    },
    {
      id: 10,
      value: 'October',
    },
    {
      id: 11,
      value: 'November',
    },
    {
      id: 12,
      value: 'December',
    },
  ],

  year() {
    const yearlist = [];
    const start = new Date().getFullYear();

    for (let i = start; i <= start + 10; i += 1) {
      yearlist.push({ id: i, name: i });
    }

    return yearlist;
  },

  titleGroupHeaders: [
    {
      headerId: 1,
      headerName: 'Title Name',
      sortable: false,
    },
    {
      headerId: 2,
      headerName: 'View title group',
      hiddenHeaderName: true,
      width: '42px',
    },
  ],
  addSlotValidation: {
    errorNullDate: false,
    errorNullTime: false,
    errorDateTime: false,
    receedDate: false,
    exceedDate: false,
    receedTime: false,
    exceedTime: false,
    errorTitle: false,
    errorDuration: false,
    errorNumberOfSlots: false,
    errorLiveName: false,
    errorSelectedDuration: false,
    invalidDate: false,
    errorRecurrence: '',
  },
  addFormTableHeights: 668,
  addTitlesTableHeight: 502,
  formTypes: {
    add: 'ADD',
    confirm: 'CONFIRM',
    confirmSubmit: 'CONFIRM SUBMIT',
    delete: 'DELETE',
    edit: 'EDIT',
    export: 'EXPORT',
    importForm: 'IMPORT',
    insert: 'INSERT',
    op2mise: 'OP2MISE',
    paste: 'PASTE',
    submit: 'SUBMIT',
  },
  importFilter: {
    all: 'ALL',
    Imported: 'IMPORTED',
    NotImported: 'NOT IMPORTED',
  },
  timeFormat: {
    default: 'Default',
    standard: 'Standard',
  },
  defaultChannel: {
    id: 0,
    name: 'Please select a channel',
  },
  weekHours: 168,
};

export default SchedulerStorage;
