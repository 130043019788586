import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Dialog } from '@progress/kendo-react-dialogs';
import Button from 'shared/components/button/Button';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';
import { formatDateWithMoment } from 'utils';
import { Input } from '@progress/kendo-react-inputs';
import CustomTimeInput from 'shared/components/custom-time-input/CustomTimeInput';
import Switch from 'shared/components/switch/Switch';
import style from './../architecture.module.css';
import Select from 'shared/components/select/Select';
import {
  ActionTypes,
  Layouts,
  BlockTypes,
  defaultModalState,
  daysAbbreviation,
} from '../ChannelArchitecture.d';
import { cAModalReducer } from '../reducers/schedules.reducer';
import { dayOfWeek } from '@progress/kendo-date-math';
import { guid } from '@progress/kendo-react-common';
import ReccurencePatternComponent from 'components/schedule/react-scheduler/forms/ReccurencePatternComponent';
import OpTimeInput from 'op2mise-react-widgets/OpTimeInput/OpTimeInput';
import { Title } from '@mui/icons-material';

const ModifyBlock = ({
  schedules,
  setIsDialogOpen,
  blockData,
  numberOfWeeks,
  setNumberOfWeeks,
  modalType,
  titleGroupList,
  seriesList,
  updateItem,
  referenceList,
  insertItem,
}) => {

  const instance = new Internationalization();
  const [recurencePattern, setRecurencePattern] = React.useState({
    id: 2,
    name: 'Daily',
  });

  const [modalState, dispatchModalState] = React.useReducer(
    cAModalReducer,
    defaultModalState
  );
  const [references, setReferences] = React.useState(referenceList.filter((o) => o.id !== blockData.Id));

  // Input Component for Modify Block Dialog
  const InputComponent = ({ label, input, gap }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        gap: gap ?? 20,
      }}
    >
      {label && <p>{label}</p>}
      {input}
    </div>
  );

  const daysOfWeek = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 7, name: 'Sunday' },
  ];

  // Get the selected day of the week
  const getSelectedDayOfWeek = (date) => {
    // Get the full name of the day of the week
    const day = instance.formatDate(new Date(date), {
      skeleton: 'E',
      type: 'date',
    });
    return daysOfWeek.find((dayOfWeek) => dayOfWeek.name.includes(day));
  };

  // Set the block data to be modified for the Modify Block Dialog
  React.useEffect(() => {
    if (blockData && Object.keys(blockData).length !== 0) {
      const {
        State,
        StartTime,
        EndTime,
        Subject,
        IsSequential,
        IsLinked,
        BlockReference,
        TitleGroupName,
        BlockName,
        Genre,
        Week,
        DayOfWeek,
        MaxCount,
        AvailableDuration,
        Layout,
        SeriesName,
        Type,
        SeasonID,
        TitleGroupID,
      } = blockData;

      const selectedSeries = seriesList.find((o) => o.name === SeriesName);
      const selectedSeason =
        selectedSeries && SeasonID
          ? selectedSeries.seasons.find((o) => o.id === SeasonID)
          : null;
      const selectedTitleGroup = titleGroupList.find(
        (o) => o.id === TitleGroupID
      );
      const selectedLinkReference = referenceList.find(
        (o) => o.blockName === BlockReference
      );
      dispatchModalState({
        action: 'changeValue',
        newValues: {
          type: Type,
          weekNumber: 1,
          dayOfWeek: getSelectedDayOfWeek(StartTime),
          startTime: StartTime,
          endTime: EndTime,
          hasLink: IsLinked,
          sequential: IsSequential,
          series: selectedSeries,
          seasons: selectedSeason,
          genre: Genre,
          layout: Layout,
          programMaxCount: MaxCount,
          programAvailableDuration: AvailableDuration,
          titleGroup: selectedTitleGroup,
          linkReference: selectedLinkReference,
        },
      });

      dispatchModalState({
        action: 'changeType',
        type: Type,
      });

      // TASK-5878 PROD ISSUE (13NOV2024): Removing this if block
      // This filter function filters out all elements, leaving the dropdown empty because no element meets the condition.

      // if (modalType == ActionTypes.UPDATE) {
      //   setReferences(
      //     referenceList.filter((o) => !o.linked && o.blockName !== BlockName)
      //   );
      // }
    }
  }, []);

  // Get max number of weeks to populate the week number select
  const getWeeks = () => {
    let weeks = [];
    for (let i = 1; i <= 52; i++) {
      weeks.push(i);
    }
    return weeks.map((week) => ({ id: week, name: week }));
  };

  /**
   * Builds a daily recurrence pattern for events.
   *
   * @param {number} day - The day of the week (1 = Monday, 2 = Tuesday, ..., 7 = Sunday).
   * @param {number} weeks - The recurrence week (1 = Week 1, 2 = Week 2, ..., 0 = Every Week).
   * @param {Object} block - The event block that will be generated based on the recurrence pattern.
   * @param {number} intervalId - The ID of the interval for which the blocks will be created.
   *                            - 1: Every {Monday | Tuesday | Wednesday | Thursday | Friday | Saturday | Sunday}
   *                            - 2: Every Weekday
   *                            - 3: Every Weekend
   */
  const buildDailyRecurrence = (day, weeks, block, intervalId) => {
    const generatedSchedules = [];
    const linkReferenceName = (dayNo, weekNo, startTime) => `${dayNo}${weekNo}${startTime}`
    let daysToAdd = [];
    let prevBlock = block.blockReference ? block.blockReference : '';

    switch (intervalId) {
      case 1: //every selected day
        daysToAdd = [day];
        break;
      case 2: //every weekdays
        daysToAdd = [1, 2, 3, 4, 5];
        break;
      case 3: //every weekends
        daysToAdd = [6, 7];
        break;
    }

    if (!weeks) {
      // Block/s will be generated every week for {numberOfWeeks} weeks
      [...Array(numberOfWeeks).keys()].forEach((weekIteration) => {
        daysToAdd.map((value) => {
          const linkReference = linkReferenceName(daysAbbreviation[value - 1], weeks, block.startTime);
          generatedSchedules.push({
            ...block,
            id: guid(),
            week: weekIteration + 1,
            dayOfWeek: value,
            blockName: linkReference,
            link: prevBlock != '' ? true : block.link,
            blockReference: prevBlock,
          });
          prevBlock = linkReference;
        });
      })
    } else {
      // Block/s will be generated for week {weeks}
      daysToAdd.map((value) => {
        const linkReference = linkReferenceName(daysAbbreviation[value - 1], weeks, block.startTime);
        generatedSchedules.push({
          ...block,
          id: guid(),
          week: weeks,
          dayOfWeek: value,
          blockName: linkReference,
          link: prevBlock != '' ? true : block.link,
          blockReference: prevBlock,
        });
        prevBlock = linkReference;
      });
    }
    return generatedSchedules;
  };

  const buildWeeklyRecurrence = (days, weekStart, weekInterval, block) => {
    const toInsert = [];
    let prevBlock = block.blockReference ? block.blockReference : '';
    for (var i = weekStart; i <= numberOfWeeks; i += parseInt(weekInterval)) {
      days.map((value) => {
        toInsert.push({
          ...block,
          id: guid(),
          week: i,
          dayOfWeek: value,
          blockName: daysAbbreviation[value - 1] + i + block.startTime,
          link: prevBlock != '' ? true : block.link,
          blockReference: prevBlock,
        });
        prevBlock = daysAbbreviation[value - 1] + i + block.startTime;
      });
    }
    return toInsert;
  };

  const canAddDay = (linkedProgram, temp) => {
    const canAddWeek = linkedProgram.week < numberOfWeeks;
    const canAddDay = linkedProgram.dayOfWeek == 7 ? canAddWeek : true;
    return canAddDay;
  };

  const hasOverlapping = (tempList, compareList) => {
    let isOverlap = false;
    const allBlocks = compareList;
    tempList.map((value) => {
      const sameDayBlocks = allBlocks.filter(
        (o) =>
          o.dayOfWeek === value.dayOfWeek &&
          o.week === value.week &&
          o.id != value.id
      );
      const endTimeValue =
        value.endTime === '00:00:00' && value.startTime === '00:00:00'
          ? '24:00:00'
          : value.endTime;
      if (!isOverlap) {
        const overlapSameDay =
          sameDayBlocks.length == 0
            ? false
            : sameDayBlocks.some(
              (o) =>
                o.startTime <= value.startTime && o.endTime > value.startTime
            ) ||
            sameDayBlocks.some(
              (o) =>
                o.startTime >= value.startTime && o.startTime < endTimeValue
            ) ||
            sameDayBlocks.some(
              (o) =>
                o.startTime >= value.startTime &&
                value.startTime > endTimeValue
            );
        if (overlapSameDay) {
          isOverlap = true;
        }

        //check before day if not first day
        if (!isOverlap && !(value.dayOfWeek === 1 && value.week === 1)) {
          const dayBefore =
            parseInt(value.dayOfWeek) === 1 ? 7 : parseInt(value.dayOfWeek) - 1;
          const weekBefore =
            dayBefore === 7 ? parseInt(value.week) - 1 : value.week;
          const beforeDayBlocks = allBlocks
            .filter((o) => o.dayOfWeek === dayBefore && o.week === weekBefore)
            .sort((a, b) =>
              a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0
            );
          if (beforeDayBlocks.length > 0) {
            //get latest block
            const overlapDayBefore = beforeDayBlocks.some(
              (o) =>
                o.endTime > value.startTime &&
                o.endTime > value.startTime &&
                o.startTime > o.endTime
            );
            if (overlapDayBefore) isOverlap = true;
          }
        }

        //check after day if not last day
        const overnightBlock = value.startTime > endTimeValue;
        if (
          !(value.dayOfWeek === 7 && value.week === numberOfWeeks) &&
          overnightBlock
        ) {
          const dayAfter =
            parseInt(value.dayOfWeek) === 7 ? 1 : parseInt(value.dayOfWeek) + 1;
          const weekAfter =
            dayAfter === 1 ? parseInt(value.week) + 1 : value.week;
          const afterDayBlocks = allBlocks
            .filter((o) => o.dayOfWeek === dayAfter && o.week === weekAfter)
            .sort((a, b) =>
              a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0
            );
          if (afterDayBlocks.length > 0) {
            //get first block
            const overlapDayAfter = afterDayBlocks.some(
              (o) => o.startTime < endTimeValue && o.startTime < o.endTime
            );
            if (overlapDayAfter) isOverlap = true;
          }
        }
      }
    });
    return isOverlap;
  };

  const handleErrorMessage = (message) => {
    dispatchModalState({
      action: 'changeValue',
      newValues: {
        errorMessage: message,
      },
    });
  };

  const getLinkReferenceInfo = (data) => {
    const linkedProgram = schedules.programmes.find(
      (o) => o.id === data?.id
    );
    return linkedProgram;
  }
  const handleModifyBlock = (props) => {
    if (!props.StartTime || !props.EndTime) {
      handleErrorMessage('Start and end time is required.');
      return;
    }
    let isValid = true;
    const selectedReference = references.find((o) => o.name === modalState.linkReference?.name);
    const linkedProgram = getLinkReferenceInfo(selectedReference);

    // Change when backend is ready
    const edited = !schedules
      ? {}
      : schedules.programmes.find((o) => o.id === props.Id);
    const temp = {
      ...edited,
      genre: modalState.genre,
      maxCount: modalState.programMaxCount,
      availableDuration: modalState.programAvailableDuration,
      week: props.Week,
      dayOfWeek: props.DayOfWeek.id,
      startTime: formatDateWithMoment(props.StartTime, 'HH:mm:ss'),
      endTime: formatDateWithMoment(props.EndTime, 'HH:mm:ss'),
      layout: modalState.layout,
      link: modalState.hasLink,
      sequential: modalState.sequential,
      type: props.type,
      series: props.type === 'Repeat' ? linkedProgram.seriesID : modalState.series,
      seasonID: props.SeasonID,
      seriesID: modalState.series && modalState.series.id,
      seriesName: props.type === 'Repeat' ? linkedProgram.seriesName : modalState.series && modalState.series.name,
      titleGroupName: props.type === 'Repeat' ? linkedProgram.titleGroupName : props.TitleGroupName,
      titleGroupID: props.type === 'Repeat' ? linkedProgram.titleGroupName : props.TitleGroupID,
      blockReference:
        modalState.linkReference && modalState.linkReference.blockName,
      blockName:
        daysAbbreviation[props.DayOfWeek.id - 1] +
        formatDateWithMoment(props.StartTime, 'HH:mm'),
    };
    if (modalType == ActionTypes.UPDATE) {
      if (!hasOverlapping([temp], schedules.programmes)) {
        //check overlap
        updateItem({ item: temp });
      } else {
        handleErrorMessage(
          'Unable to update block. A new block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your new block to avoid conflicts.'
        );
        isValid = false;
      }
    } else {
      if (Object.keys(recurencePattern).length === 0) {
        // After Link
        const linkedProgram = schedules.programmes.find(
          (o) => o.blockName === temp.blockReference
        );
        if (!linkedProgram) {
          handleErrorMessage('Link reference is required.');
          isValid = false;
        } else {
          const isAddDay = linkedProgram.endTime === '00:00:00';
          const validLink = isAddDay ? canAddDay(linkedProgram, temp) : true;
          if (validLink) {
            const day = isAddDay
              ? parseInt(linkedProgram.dayOfWeek) == 7
                ? 1
                : parseInt(linkedProgram.dayOfWeek) + 1
              : parseInt(linkedProgram.dayOfWeek);
            const week =
              isAddDay && parseInt(linkedProgram.dayOfWeek) == 7
                ? parseInt(linkedProgram.week) + 1
                : linkedProgram.week;
            const toInsert = {
              ...temp,
              id: guid(),
              week: week,
              dayOfWeek: day,
              blockName: daysAbbreviation[day - 1] + week + temp.startTime,
            };
            if (!hasOverlapping([toInsert], schedules.programmes)) {
              //check overlap
              insertItem({
                item: [toInsert],
              });
            } else {
              handleErrorMessage(
                'Unable to insert new block. A new block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your new block to avoid conflicts.'
              );
              isValid = false;
            }
          } else {
            handleErrorMessage('After link reference is out of bounds');
            isValid = false;
          }
        }
      } else {
        const {
          intervalId,
          intervalValue,
          reccurencePatternId,
          week,
          everyWeekInterval,
          days,
        } = recurencePattern;
        switch (reccurencePatternId) {
          // Daily pattern
          case 2:
            const dailyInsertList = buildDailyRecurrence(
              intervalValue,
              week.id,
              temp,
              intervalId
            );
            if (
              !hasOverlapping(dailyInsertList, dailyInsertList) &&
              !hasOverlapping(dailyInsertList, schedules.programmes)
            ) {
              insertItem({
                item: dailyInsertList,
              });
            } else {
              handleErrorMessage(
                'Unable to insert new block. A new block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your new block to avoid conflicts.'
              );
              isValid = false;
            }
            break;

          case 3:
            //weekly pattern
            const weeklyInsertList = buildWeeklyRecurrence(
              days,
              props.Week,
              everyWeekInterval,
              temp
            );
            if (
              !hasOverlapping(weeklyInsertList, weeklyInsertList) &&
              !hasOverlapping(weeklyInsertList, schedules.programmes)
            ) {
              insertItem({
                item: weeklyInsertList,
              });
            } else {
              handleErrorMessage(
                'Unable to insert new block. A new block cannot overlap with the duration of an existing block. Please adjust the week, day, start or end time of your new block to avoid conflicts.'
              );
              isValid = false;
            }
            break;
        }
      }
    }

    isValid && setIsDialogOpen(false);
  };

  const changeType = (args) => {
    dispatchModalState({
      action: 'changeType',
      type: args.name,
    });
  };

  const changeForm = (attribute, value) => {
    dispatchModalState({
      action: 'changeValue',
      newValues: {
        [attribute]: value,
      },
    });
  };

  const changeLinkRefenrece = (args) => {
    const linkedProgram = schedules.programmes.find(
      (o) => o.blockName === args.blockName
    );
    // TASK-5878 PROD ISSUE (13NOV2024): Removing the below lines
    // As per this bug, we won't be redefining the Start Time date value when linking.

    // var startDate = linkedProgram ? new Date() : modalState.startTime;
    // if (linkedProgram) {
    //   const endTimeSplit = linkedProgram.endTime.split(':');
    //   startDate.setHours(endTimeSplit[0]);
    //   startDate.setMinutes(endTimeSplit[1]);
    //   startDate.setMilliseconds(endTimeSplit[2]);
    // }
    dispatchModalState({
      action: 'changeValue',
      newValues: {
        linkReference: args,
        // startTime: startDate,
        disableStartTime: linkedProgram ? true : false,
      },
    });
  };

  const handleToggleLink = (value) => {
    dispatchModalState({
      action: 'changeValue',
      newValues: {
        hasLink: value,
        disableStartTime: false,
        linkReference: null,
      },
    });
  };


  return (
    <Modal
      show={true}
      onHide={() => setIsDialogOpen(false)}
      style={{ '--bs-modal-width': '1030px' }}
    >
      <Modal.Body>
        <div style={{ padding: '30px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 7,
              fontSize: '20px',
            }}
          >
            {modalType == ActionTypes.UPDATE ? 'Modify' : 'New'} Block
            {modalType == ActionTypes.UPDATE && <span>|</span>}
            <div style={{ color: '#6D6D73' }}>{blockData.BlockName}</div>
          </div>
          <div
            style={{
              display: 'flex',
              padding: '25px 0',
              gap: 65,
              fontSize: '14px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                flex: 1,
                width: '50%',
              }}
            >
              <InputComponent
                label="Type"
                input={
                  <Select
                    text={modalState.type}
                    value={modalState.type}
                    list={BlockTypes}
                    onSelect={(args) => {
                      changeType(args);
                    }}
                    width="250px"
                  />
                }
              />

              <InputComponent
                label="Genre"
                input={
                  <Input
                    className={`${style.modalInput}`}
                    type="text"
                    defaultValue={modalState.genre}
                    placeholder="Enter a preferred genre"
                    onBlur={(e) => changeForm('genre', e.target.value)}
                    style={{ width: '252px' }}
                    disabled={modalState.disabledGenre}
                  />
                }
              />

              <InputComponent
                label="Program MAX Count"
                input={
                  <input
                    type="number"
                    defaultValue={modalState.programMaxCount}
                    min={1}
                    max={9999}
                    onBlur={(e) =>
                      changeForm('programMaxCount', e.target.value)
                    }
                    style={{ width: '190px' }}
                    className={`${style.modalInput} ${style.inputWrapper}`}
                    disabled={modalState.disableMaxCount}
                    placeholder="0"
                  />
                }
              />

              <InputComponent
                label="Program Available Duration"
                input={
                  <input
                    type="number"
                    defaultValue={modalState.programAvailableDuration}
                    min={1}
                    max={9999}
                    onBlur={(e) =>
                      changeForm('programAvailableDuration', e.target.value)
                    }
                    style={{ width: '190px' }}
                    className={`${style.modalInput} ${style.inputWrapper}`}
                    disabled={modalState.disableAvailableDuration}
                    placeholder=":MM"
                  />
                }
              />

              <InputComponent
                label="Series"
                input={
                  <Select
                    text={
                      modalState.series
                        ? modalState.series.name
                        : 'Please select a preferred series'
                    }
                    value={modalState.series}
                    list={seriesList}
                    onSelect={(args) => {
                      changeForm('series', args);
                    }}
                    width="250px"
                    disabled={modalState.type !== 'Series'}
                  />
                }
              />

              <InputComponent
                label="Seasons"
                input={
                  <Select
                    text={
                      (modalState.type === 'Series' && modalState.series)
                        ? 'Please select a preferred season'
                        : modalState.seasons && modalState.seasons.name
                          ? modalState.seasons.name
                          : 'Please select a preferred series'
                    }
                    list={modalState.series ? modalState.series.seasons : []}
                    onSelect={(args) => {
                      changeForm('seasons', args);
                    }}
                    width="250px"
                    disabled={modalState.type !== 'Series'}
                  />
                }
              />

              {modalType == ActionTypes.UPDATE && (
                <InputComponent
                  label="Title Group"
                  input={
                    <Select
                      text={
                        modalState.titleGroup
                          ? modalState.titleGroup.name
                          : 'Please select a preferred title group'
                      }
                      value={modalState.titleGroup}
                      list={titleGroupList}
                      onSelect={(args) => {
                        changeForm('titleGroup', args);
                      }}
                      width="250px"
                      disabled={modalState.type !== "TitleGroup"}
                    />
                  }
                />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                flex: 1,
                width: '50%',
              }}
            >
              {modalType == ActionTypes.UPDATE && (
                <>
                  <InputComponent
                    label="Day of Week"
                    input={
                      <Select
                        text={modalState.dayOfWeek.name}
                        value={modalState.dayOfWeek.name}
                        list={daysOfWeek}
                        onSelect={(args) => {
                          changeForm('dayOfWeek', args);
                        }}
                        width="250px"
                      />
                    }
                  />
                </>
              )}

              <InputComponent
                label="Start Time*"
                input={
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 15 }}
                  >
                    <div style={{ width: '149px' }}>
                      <OpTimeInput
                        value={modalState.startTime}
                        onChange={(e) => changeForm('startTime', e)}
                        width="full"
                      // TASK-5878 PROD ISSUE (13NOV2024): Removing this `disabled` prop
                      // Start Time text field should NEVER be disabled even when linking.
                      />
                    </div>
                    <Switch
                      label="Link"
                      labelStyle={{ color: '#6D6D73' }}
                      text={['No', 'Yes']}
                      active={modalState.hasLink}
                      disabled={false}
                      onSwitch={() => {
                        handleToggleLink(!modalState.hasLink);
                      }}
                      blurredOnInactive={false}
                      cssClass={style.switchClass}
                    />
                  </div>
                }
              />

              <InputComponent
                label={'Link Reference*'}
                input={
                  <Select
                    text={
                      modalState.linkReference
                        ? modalState.linkReference.name
                        : 'Please select an existing block'
                    }
                    list={references}
                    onSelect={(args) => {
                      changeLinkRefenrece(args);
                    }}
                    width="250px"
                    disabled={modalState.disableLinkReference}
                  />
                }
              />

              <InputComponent
                label={'End Time*'}
                input={
                  <div style={{ width: '250px' }}>
                    {/* <CustomTimeInput
                      value={modalState.endTime}
                      onChange={(e) => changeForm('endTime', e)}
                    /> */}
                    <OpTimeInput
                      value={modalState.endTime}
                      onChange={(e) => changeForm('endTime', e)}
                      width="full"
                    />
                  </div>
                }
              />

              <InputComponent
                label={'Layout'}
                input={
                  <Select
                    text={modalState.layout}
                    list={Layouts}
                    onSelect={(args) => {
                      changeForm('layout', args.name);
                    }}
                    width="250px"
                  />
                }
              />
              {modalType == ActionTypes.CREATE && (
                <InputComponent
                  label="Title Group"
                  input={
                    <Select
                      text={
                        modalState.titleGroup
                          ? modalState.titleGroup.name
                          : 'Please select a preferred title group'
                      }
                      value={modalState.titleGroup}
                      list={titleGroupList}
                      onSelect={(args) => {
                        changeForm('titleGroup', args);
                      }}
                      width="250px"
                      disabled={modalState.disableTitleGroup}
                    />
                  }
                />
              )}
            </div>
          </div>
          <hr></hr>
          {/* Recurrence settings */}
          {modalType == ActionTypes.CREATE && (
            <div style={{ display: 'flex' }}>
              <ReccurencePatternComponent
                isRegular={true}
                patterns={
                  modalState.hasLink
                    ? [
                      { id: 2, name: 'Daily' },
                      { id: 3, name: 'Weekly' },
                      { id: 1, name: 'After Link' },
                    ]
                    : [
                      { id: 2, name: 'Daily' },
                      { id: 3, name: 'Weekly' },
                    ]
                }
                onChangeRecurrence={(args) => setRecurencePattern(args)}
                numberOfWeeks={numberOfWeeks}
                type="dropdown"
              />
            </div>
          )}
          <div
            class="errorValidation"
            style={{ whiteSpace: 'initial', width: '800px' }}
          >
            {modalState.errorMessage}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Button
              defaultBtn
              text="Cancel"
              onClick={() => setIsDialogOpen(false)}
            />

            <Button
              text={modalType === ActionTypes.UPDATE ? 'Update' : 'Insert'}
              type="button"
              onClick={() => {
                handleModifyBlock({
                  ...blockData,
                  EndTime: modalState.endTime,
                  StartTime: modalState.startTime,
                  DayOfWeek: modalState.dayOfWeek,
                  Week: 1,
                  Genre: modalState.genre,
                  Layout: modalState.layout,
                  IsLink: modalState.hasLink,
                  IsSequential: modalState.sequential,
                  type: modalState.type,
                  SeasonID: modalState.seasons && modalState.seasons.id.toString(),
                  SeriesName: modalState.series && modalState.series.name,
                  TitleGroupID: modalState.titleGroup && modalState.titleGroup.id,
                  TitleGroupName: modalState.titleGroup && modalState.titleGroup.name,
                });
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModifyBlock;
